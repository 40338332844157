import React from 'react';
import Button from "../../common/Button";
import {showCustomersNumber} from "../../../services/utils";
import {formatNumber} from "../../../services/numbers";
import AddImage from "../../merchant_point/AddImage";
import AddVideo from "./AddVideo";
import {CustomersCost, VideoLayout} from "../../../services/interfaces";
import {Tooltip} from "react-tooltip";
import {cloudFrontUrl, icons} from "../../../services/images";

interface StepProps {
    formData: {
        image_path: string;
        film_path: string;
        film_title: string;
        film_layout: VideoLayout;
        film_file: File|null;
    };
    isEditing: boolean;
    handleChange: (field: string, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
    oldCost: number;
    customersCost: CustomersCost|null;
}

const Step3: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        prevStep,
                                        nextStep,
                                        numberOfCustomers,
                                        estimateCost,
                                        oldCost,
                                        isEditing,
                                        customersCost
                                    }) => {
    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label">Zdjęcie promocji:</p>
                    <div className="add-image">
                        <AddImage
                            label=""
                            width={1200}
                            height={800}
                            type="offer-image"
                            image={formData.image_path}
                            setImage={(image) => handleChange('image_path', image)}
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Film promocyjny:</p>
                        <div className="input mb16">
                            <input type="text"
                                   className="input-text"
                                   value={formData.film_title}
                                   onChange={(e) => handleChange("film_title", e.target.value)}
                                   placeholder="Podaj nazwę filmu promocyjnego"
                            />
                        </div>
                        <div className="video-wrapper">
                            <AddVideo
                                videoLayout={formData.film_layout}
                                video={isEditing && formData.film_path!=="" ? `${cloudFrontUrl}${formData.film_path}` : formData.film_path}
                                setVideoUrl={(video) => handleChange("film_path", video)}
                                setVideoFile={(file) => handleChange("film_file", file)}
                            />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p className="label label-margin">Układ filmu:</p>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="layout"
                                checked={formData.film_layout === "horizontal"}
                                value="horizontal"
                                onChange={(e)=>handleChange("film_layout",e.target.value)}
                            />
                            <p className="label">poziomy</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="layout"
                                checked={formData.film_layout === "vertical"}
                                value="vertical"
                                onChange={(e)=>handleChange("film_layout",e.target.value)}
                            />
                            <p className="label">pionowy</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label w700">{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                   <div className="offer-wrapper">
                        <div className="wrapper">
                            <span className="label">Łączny koszt promocji: </span>
                            <div className="icon-wrapper">
                                <Tooltip id="customers prize" className="tooltip" noArrow={true}>
                                        {customersCost ?
                                            <>
                                                <p className="tooltip-body">Cena za obecnego
                                                klienta: {formatNumber(customersCost.current_customer)}</p><p
                                                className="tooltip-body">Cena za ręcznie dodanego obecnego
                                                klienta: {formatNumber(customersCost.added_customer)}</p><p
                                                className="tooltip-body">Cena za nowego
                                                klienta: {formatNumber(customersCost.new_customer)}</p>
                                            </>
                                        :
                                            <p className="tooltip-body">Pobieranie aktualnych danych...</p>
                                        }
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="customers prize" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <span
                            className="label w700">{formatNumber(estimateCost)}</span>
                    </div>
                    {isEditing  && oldCost < estimateCost &&
                        <div className="offer-wrapper blue">
                            <span className="label">Do dopłaty: </span>
                            <span
                                className="label w700">{formatNumber(estimateCost - oldCost)}</span>
                        </div>
                    }
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            onClick={nextStep}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step3;