import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {getReservationFilterOptionsHandler} from "../../../handlers/bookingHandlers";
import {FilterModalProps, MultiSelectOption, ReservationFilterOptions} from "../../../services/interfaces";
import {getReservationFilters} from "../../../redux/selectors/bookingSelector";
import {getFiltersClientOptions, getFiltersEmployeesOptions} from "../../../services/helpers";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import {toLocalISOString} from "../../../services/dates";
import {icons} from "../../../services/images";
import FiltersDropdown from "../../modals/filters/FiltersDropdown";
import FiltersDatePicker from "../../modals/filters/FiltersDatePicker";
import FiltersSlider from "../../modals/filters/FiltersSlider";
import Button from "../../common/Button";
import ReservationStatusSelect from "./ReservationStatusSelect";

export const DEFAULT_RESERVATION_STATUS_STATE = {
    placed: false,
    paid: false,
    canceled: false,
    completed: false,
};

export interface ReservationStatusState {
    placed: boolean,
    paid: boolean,
    canceled: boolean,
    completed: boolean,
}


const ReservationFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: ReservationFilterOptions | null = useSelector(getReservationFilters);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    useEffect(() => {
        getReservationFilterOptionsHandler()
    }, [merchantPoint]);


    const [clients, setClients] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [employees, setEmployees] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const [status, setStatus] = useState<ReservationStatusState>(DEFAULT_RESERVATION_STATUS_STATE);

    const [amountSlider, setAmountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_amount, filterOptions.max_amount] : null);

    if (filterOptions && (amountSlider === null)) {
        setAmountSlider([filterOptions.min_amount, filterOptions.max_amount]);
    }

    const columnsOptions: MultiSelectOption[] = [
        {value: 'ID rezerwacji', children: 'id'},
        {value: 'ID klienta', children: 'client_id'},
        {value: 'Pracownik', children: 'employee'},
        {value: 'Data wizyty', children: 'appointment_date'},
        {value: 'Status', children: 'status'},
        {value: 'Rodzaj usługi', children: 'service_name'},
        {value: 'Kwota', children: 'amount'},
    ];

    const clientOptions = getFiltersClientOptions(filterOptions);
    const employeeOptions: MultiSelectOption[] = getFiltersEmployeesOptions(filterOptions);

    const clearFilters = () => {
        setClients([]);
        setColumns([]);
        setEmployees([]);
        setFromDate(null);
        setToDate(null);
        setStatus(DEFAULT_RESERVATION_STATUS_STATE);
        setAmountSlider([filterOptions?.min_amount || 0, filterOptions?.max_amount || 100]);
    };

    const applyFilters = () => {

        if (!amountSlider) {
            return;
        }

        const statusString = Object.keys(status)
            .filter(key => status[key as keyof ReservationStatusState])
            .join(',');
        const urlFilters = '' +
            'clients=' + clients.join(',') +
            '&columns=' + columns.join(',') +
            '&employees=' + employees.join(',') +
            '&fromDate=' + (fromDate ? toLocalISOString(fromDate) : '') +
            '&toDate=' + (toDate ? toLocalISOString(toDate) : '') +
            '&status=' + statusString +
            '&amount=' + amountSlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                <FiltersDropdown
                    title="Wybierz klienta"
                    placeholder="Wybierz klienta po user ID..."
                    options={clientOptions}
                    values={clients}
                    setValues={setClients}
                />
                {filterOptions && Object.keys(filterOptions.employees).length > 0 &&
                    <FiltersDropdown
                        title="Wybierz pracownika"
                        placeholder="Wybierz pracownika..."
                        options={employeeOptions}
                        values={employees}
                        setValues={setEmployees}
                        setChildren={true}
                    />
                }
                <FiltersDatePicker
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    minTodayDate={false}
                    maxTodayDate={false}
                />
                <ReservationStatusSelect status={status} setStatus={setStatus}/>
                <FiltersSlider
                    label="Kwota (zł)"
                    min={filterOptions?.min_amount || 0}
                    max={filterOptions?.max_amount || 100}
                    value={amountSlider}
                    onChange={setAmountSlider}
                />
            </div>
            <div className="modal-footer">
                {filterOptions && <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default ReservationFiltersModal;