import moment from "moment/moment";
import {DatePreset, DateRange, PresetType, Reservation} from "./interfaces";
import {Moment} from "moment";


export const DEFAULT_START_DATE = moment().subtract(29, 'days').format('YYYY-MM-DD');
export const DEFAULT_END_DATE = moment().format('YYYY-MM-DD');


export const getPresetRange = (type: PresetType, firstTransactionDate?: string | null): Moment[] => {
    switch (type) {
        case 'week':
            return [moment().startOf('week'), moment()];
        case 'month':
            return [moment().startOf('month'), moment()];
        case 'quarter':
            return [moment().startOf('quarter'), moment()];
        case 'halfYear':
            return [moment().subtract(6, 'months'), moment()];
        case 'year':
            return [moment().startOf('year'), moment()];
        case 'last7Days':
            return [moment().subtract(6, 'days'), moment()];
        case 'last30Days':
            return [moment().subtract(29, 'days'), moment()];
        case 'last90Days':
            return [moment().subtract(89, 'days'), moment()];
        case 'last365Days':
            return [moment().subtract(364, 'days'), moment()];
        case 'fullRange':
            return firstTransactionDate
                ? [moment(firstTransactionDate), moment()]
                : [moment(), moment()];
    }
};

export const DEFAULT_PRESET: DatePreset = {
    type: 'last30Days',
    datePickerLabel: "Ostatnie 30 dni",
    label: "Ostatnie 30 dni"
};

export const DEFAULT_PRESETS: DatePreset[] = [
    {type: 'week', datePickerLabel: "Tydzień", label: "Tydzień"},
    {type: 'month', datePickerLabel: "Miesiąc", label: "Miesiąc"},
    {type: 'quarter', datePickerLabel: "Kwartał", label: "Kwartał"},
    {type: 'halfYear', datePickerLabel: "Pół roku", label: "Półrocze"},
    {type: 'year', datePickerLabel: "Rok", label: "Rok"},
    {type: 'last7Days', datePickerLabel: "Ostatnie 7 dni", label: "Ostatnie 7 dni"},
    {type: 'last30Days', datePickerLabel: "Ostatnie 30 dni", label: "Ostatnie 30 dni"},
    {type: 'last90Days', datePickerLabel: "Ostatnie 90 dni", label: "Ostatnie 90 dni"},
    {type: 'last365Days', datePickerLabel: "Ostatnie 365 dni", label: "Ostatnie 365 dni"}
];


export const getDateRangeDays = (dateRange: DateRange) => moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days') + 1;

export const toLocalISOString = (date: Date) => {
    const pad = (n: number) => n.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const dateGroups = ['today', 'yesterday', 'last7Days', 'last30Days', 'olderThanMonth'];

export const getDateGroupTitle = (group: string) => {
    switch (group) {
        case 'today':
            return 'Dzisiaj';
        case 'yesterday':
            return 'Wczoraj';
        case 'last7Days':
            return 'Ostatnie 7 dni';
        case 'last30Days':
            return 'Ostatnie 30 dni';
        default:
            return 'Starsze niż 30 dni'
    }
};

export const categorizeByDate = <T>(data: T[], dateKey: keyof T) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const groups: { [key: string]: T[] } = {
        today: [],
        yesterday: [],
        last7Days: [],
        last30Days: [],
        olderThanMonth: []
    };

    data.forEach(item => {
        const itemDate = new Date(item[dateKey] as unknown as string);
        itemDate.setHours(0, 0, 0, 0);

        if (itemDate >= today) {
            groups.today.push(item);
        } else if (itemDate >= yesterday) {
            groups.yesterday.push(item);
        } else if (itemDate >= last7Days) {
            groups.last7Days.push(item);
        } else if (itemDate >= last30Days) {
            groups.last30Days.push(item);
        } else {
            groups.olderThanMonth.push(item);
        }
    });

    return groups;
};

export const displayConvertedDate = (date: string) => {
    const dateObj = new Date(date);
    const today = new Date();
    const isToday = dateObj.getDate() === today.getDate() &&
        dateObj.getMonth() === today.getMonth() &&
        dateObj.getFullYear() === today.getFullYear();

    if (isToday) {
        return `Dzisiaj | ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    } else {
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} | ${hours}:${minutes}`;
    }
};


export const formatDateToDuration = (date: Date): number => date.getHours() * 60 + date.getMinutes();

export const formatDurationToDate = (totalMinutes: number): Date => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return new Date(0, 0, 0, hours, minutes);
};

export const formatDurationToString = (totalMinutes: number): string => {
    let formattedDuration = '';
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours > 0) {
        formattedDuration += `${hours}godz `;
    }

    if (minutes > 0) {
        formattedDuration += `${minutes}min`;
    }

    return formattedDuration.trim();
};

export const dateFormatAccountingDocument = (date: string, monthlySale: boolean) => {
    const dateObj = new Date(date);
    let dateStr = `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;
    if (monthlySale) {
        const firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
        const lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
        dateStr = `${firstDay.getDate()}.${firstDay.getMonth() + 1}.${firstDay.getFullYear()} - ${lastDay.getDate()}.${lastDay.getMonth() + 1}.${lastDay.getFullYear()}`;
    }

    return dateStr;
};

export const getReservationTime = (appointmentDate: string, duration: number)=> {
    const momentDate = moment(appointmentDate).locale('pl');
    return `${momentDate.format('HH:mm')} - ${momentDate.add(duration, 'minutes').format('HH:mm')}`
};


export const getReservationDateTime = (appointmentDate: string, duration: number) => {
    const localDate = moment(appointmentDate).locale('pl');
    const formattedDay = localDate.format('YYYY-MM-DD');
    const formattedTime = getReservationTime(appointmentDate, duration);

    return `${formattedDay} ${formattedTime}`
};

export const dayOrder = {
    'MONDAY': 1,
    'TUESDAY': 2,
    'WEDNESDAY': 3,
    'THURSDAY': 4,
    'FRIDAY': 5,
    'SATURDAY': 6,
    'SUNDAY': 7
} as const;

type DayOfWeek = keyof typeof dayOrder;

export const sortByDayOfWeek = <T extends { day_of_week: DayOfWeek }>(days: T[]): T[] => {
    return [...days].sort((a, b) => dayOrder[a.day_of_week] - dayOrder[b.day_of_week]);
};

export const isPastReservation = (reservation: Reservation) => {
    const appointmentEndTime = moment(reservation.appointment_date).add(reservation.duration, 'minutes');
    return appointmentEndTime.isBefore(moment());
};