import Modal from "react-modal";
import {cloudFrontUrl, icons, images} from "../../services/images";
import React from "react";
import dayjs from "dayjs";
import {OfferTypeMapping, OfferUsageMapping} from "../../services/dictionaries/enums";
import {StepperOfferData} from "../../services/interfaces";
import {showOfferDays} from "../../services/offerUtils";


interface PreviewModalProps {
    isOpen: boolean,
    onClose: () => void;
    formData: StepperOfferData;
    isEditing: boolean;
}

const PreviewModal = ({formData, isEditing, isOpen, onClose}: PreviewModalProps) => {
    const imageHandler = () => {
        if(isEditing){
            return `${cloudFrontUrl}${formData.step3.image_path}`;
        }
        else {
            if(formData.step3.image_path) {
                return formData.step3.image_path;
            } else {
                return images.offerImagePlaceHolder;
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="stepper-modal-content filters-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title mb8">Podgląd Promocji</h5>
                        <p className="label required-mark">Podgląd dotyczy widoku promocji z perspektywy klienta.</p>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="step-form-wrapper step-form">
                        <div className="preview-content-wrapper">
                            <img
                                className="media"
                                src={imageHandler()}
                                alt={"Offer"}/>
                            <div>
                                <h2 className="preview-h2 wrap">{formData.step2.name}</h2>
                                <p className="preview-p wrap">{formData.step2.description}</p>
                                <div className="one-line">
                                    <p className="preview-p primary">Promocja dostępna w okresie:&nbsp;</p>
                                    <span
                                        className="preview-span primary">{`${dayjs(formData.step2.start_date).format('DD.MM.YYYY')} - ${dayjs(formData.step2.end_date).format('DD.MM.YYYY')}`}</span>
                                </div>
                                <div className="days-offer-wrapper">
                                    <p className="preview-p primary">w&nbsp;</p>
                                    <p className="preview-p primary w700">
                                        {showOfferDays(formData.step2.offer_days)}.
                                    </p>
                                </div>
                                <div className="tags-wrapper">
                                    {formData.step2.usage &&
                                        <div className="tag">{OfferUsageMapping[formData.step2.usage]}</div>
                                    }
                                    {formData.step2.type &&
                                        <div className="tag">{OfferTypeMapping[formData.step2.type]}</div>
                                    }
                                </div>
                            </div>
                            {formData.step3.film_path &&
                                <div className="video-wrapper">
                                    <h3 className="preview-h3 wrap">{formData.step3.film_title !== "" && formData.step3.film_title}</h3>
                                    <video className={`media-${formData.step3.film_layout}`} controls>
                                        <source src={isEditing ? `${cloudFrontUrl}${formData.step3.film_path}` : formData.step3.film_path}
                                                type="video/mp4"/>
                                        <source src={formData.step3.film_path} type="video/ogg"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </Modal>
    );
};

export default PreviewModal;