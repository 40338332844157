import {
    AdditionalCustomer,
    CustomersCost,
    OfferData,
    OfferDay,
    OfferDays, OfferSendData,
    RangeBase,
    RangeBaseCreateData,
    StepperOfferData,
    TargetGroup,
    TargetGroupCreateData, UpdateOfferData
} from "./interfaces";
import {mapOfferDaysToString} from "./utils";
import {DayOfWeek} from "./dictionaries/enums";

export const showOfferDays =(offerDays: {value: string, label: string}[])=> {
        let index = 0
        let convertDays: OfferDay[] = offerDays.map((day:{value: string, label: string})=> {

            if(day.value)
            {
                return {id: index++, days_offer: day.value as OfferDays}
            } else {
                return {id: index++, days_offer: day as unknown as OfferDays}
            }
        }
        )
        return mapOfferDaysToString(convertDays);
    }

export const convertOfferToOfferSendData = (formData: OfferData): OfferSendData => {


    let daysOffer: any;
    if (Array.isArray(formData.offer_days)) {
        daysOffer = formData.offer_days.map((({id, ...rest}) => rest));
    }

    const offerData : OfferSendData = {
        id: formData.id,
        clients: formData.clients,
        target_group: formData.target_group,
        offer_localizations: formData.offer_localizations.map((({id, ...rest}) => rest)),
        estimated_cost: formData.estimated_cost,
        number_of_customers: formData.number_of_customers,
        extra_customers: formData.extra_customers,
        offer_user_changes: formData.offer_user_changes.map((({id, ...rest}) => rest)),
        name: formData.name,
        type: formData.type,
        extra_cashback: formData.extra_cashback,
        merchant_point_ids: formData.offer_merchant_points.map((({id, ...rest}) => id)),
        start_date: formData.start_date,
        end_date: formData.end_date,
        offer_days: daysOffer,
        usage: formData.usage,
        description: formData.description,
        direct_addressing: formData.direct_addressing,
        direct_addressing_style: formData.direct_addressing_style,
        send_push_notification: formData.send_push_notification,
        image_path: formData.image_path,
        film_path: formData.film_path,
        film_title: formData.film_title,
        film_layout: formData.film_layout,
        film_file: formData.film_file,
    }

    return offerData;
};

export const convertStepperOfferToOfferSendData = (formData: StepperOfferData, takeDeposit = false) => {
    let daysOffer: any;
    if (Array.isArray(formData.step2.offer_days)) {
        daysOffer = formData.step2.offer_days.map((day) => {
            if(day.value) {
                return {days_offer: day.value};
            } else {
                return {days_offer: day};
            }
        });
    }

    let offer_user_changes: AdditionalCustomer[] = [];
    if(Array.isArray(formData.step1.offer_user_changes)){
        offer_user_changes = formData.step1.offer_user_changes.map((customer): AdditionalCustomer => {
                return {
                    user_id: customer.id,
                    user_status: customer.status
                };
        })
    }

    const offerData : OfferSendData = {
        clients: formData.step1.clients,
        target_group: formData.step1.target_group,
        offer_localizations: [{
          longitude: formData.step1.offer_localizations.coordinates.lng.toString(),
          latitude: formData.step1.offer_localizations.coordinates.lat.toString(),
          offer_range: formData.step1.offer_localizations.offer_range,
          place_name: formData.step1.offer_localizations.place_name
        }],
        estimated_cost: formData.step1.estimated_cost,
        number_of_customers: formData.step1.number_of_customers,
        extra_customers: formData.step1.extra_customers,
        offer_user_changes: offer_user_changes,
        name: formData.step2.name,
        type: formData.step2.type,
        extra_cashback: formData.step2.extra_cashback,
        merchant_point_ids: formData.step2.offer_merchant_points,
        start_date: formData.step2.start_date,
        end_date: formData.step2.end_date,
        offer_days: daysOffer,
        usage: formData.step2.usage,
        description: formData.step2.description,
        direct_addressing: formData.step2.direct_addressing,
        direct_addressing_style: formData.step2.direct_addressing_style,
        send_push_notification: formData.step2.send_push_notification,
        image_path: formData.step3.image_path,
        film_path: formData.step3.film_path,
        film_title: formData.step3.film_title,
        film_layout: formData.step3.film_layout,
        film_file: formData.step3.film_file,
        use_deposits: takeDeposit,
    }

    return offerData;
};

export const convertStepperOfferToSendData = (formData: StepperOfferData, takeDeposit = false) => {
    let daysOffer: any;
    if (Array.isArray(formData.step2.offer_days)) {
        daysOffer = formData.step2.offer_days.map((day) => {
            if(day.value) {
                return {days_offer: day.value};
            } else {
                return {days_offer: day};
            }
        });
    }

    let offer_user_changes: AdditionalCustomer[] = [];
    if(Array.isArray(formData.step1.offer_user_changes)){
        offer_user_changes = formData.step1.offer_user_changes.map((customer): AdditionalCustomer => {
                return {
                    user_id: customer.id,
                    user_status: customer.status
                };
        })
    }

    const tg = { ...formData.step1.target_group, is_offer_duplicate: true}
    const {id, ...targetGroup } = tg

    const prepareTargetGroup = (targetGroup: TargetGroup): TargetGroupCreateData => {
        const removeIdFromRange = (range: RangeBase): RangeBaseCreateData => {
            const {id, ...rest} = range;
            return rest;
        };
        return {
            ...targetGroup,
            age_ranges: targetGroup.age_ranges.map(removeIdFromRange),
            transactions_ranges: targetGroup.transactions_ranges.map(removeIdFromRange),
            amount_ranges: targetGroup.amount_ranges.map(removeIdFromRange),
            amount_sum_ranges: targetGroup.amount_sum_ranges.map(removeIdFromRange),
        };
    };

    const sendData = new FormData();

    const offerData : OfferSendData = {
        clients: formData.step1.clients,
        target_group: prepareTargetGroup(targetGroup),
        offer_localizations: [{
          longitude: formData.step1.offer_localizations.coordinates.lng.toString(),
          latitude: formData.step1.offer_localizations.coordinates.lat.toString(),
          offer_range: formData.step1.offer_localizations.offer_range,
          place_name: formData.step1.offer_localizations.place_name
        }],
        estimated_cost: formData.step1.estimated_cost,
        number_of_customers: formData.step1.number_of_customers,
        extra_customers: formData.step1.extra_customers,
        offer_user_changes: offer_user_changes,
        name: formData.step2.name,
        type: formData.step2.type,
        extra_cashback: formData.step2.extra_cashback,
        merchant_point_ids: formData.step2.offer_merchant_points,
        start_date: formData.step2.start_date,
        end_date: formData.step2.end_date,
        offer_days: daysOffer,
        usage: formData.step2.usage,
        description: formData.step2.description,
        direct_addressing: formData.step2.direct_addressing,
        direct_addressing_style: formData.step2.direct_addressing_style,
        send_push_notification: formData.step2.send_push_notification,
        image_path: formData.step3.image_path,
        film_path: formData.step3.film_path,
        film_title: formData.step3.film_title,
        film_layout: formData.step3.film_layout,
        film_file: formData.step3.film_file,
        use_deposits: takeDeposit,
    }

    sendData.append('data', JSON.stringify(offerData));
    if (formData.step3.film_file) {
        sendData.append('film_file', formData.step3.film_file);
    }
    return sendData;
};

export const convertUpdateOfferToSendData = (formData: UpdateOfferData) => {

    let offer_user_changes: AdditionalCustomer[] = [];
    if(Array.isArray(formData.offer_user_changes)){
        offer_user_changes = formData.offer_user_changes.map((customer): AdditionalCustomer=> {
                return {
                    id: customer.id,
                    user_id: customer.user_id,
                    user_status: customer.user_status
                };
        }).filter(Boolean);
    }

    const sendData = new FormData();

    const offerData: UpdateOfferData = {
        ...formData,
        offer_localizations: formData.offer_localizations?.map(loc => ({
            longitude: loc.longitude,
            latitude: loc.latitude,
            offer_range: loc.offer_range,
            place_name: loc.place_name
        })),
        offer_days: formData.offer_days,
        offer_user_changes: offer_user_changes,
        offer_merchant_points: formData.offer_merchant_points
    };


    const filteredOfferData = Object.keys(offerData).reduce((acc, key) => {
    if ((offerData as any)[key] !== undefined) {
        (acc as any)[key] = (offerData as any)[key];
    }
    return acc;
}, {});

    sendData.append('data', JSON.stringify(filteredOfferData));

    if (formData.film_file) {
        sendData.append('film_file', formData.film_file);
    }

    return sendData;
};

export const convertToStepperOfferData = (offerData: OfferData): StepperOfferData => {
  // Konwersja offer_days do wymaganego formatu

const convertedOfferDays = (days: OfferDay[]) : { value: string; label: string }[] => {
  const dayLabels: Record<DayOfWeek, string> = {
    [DayOfWeek.MONDAY]: 'W poniedziałek',
    [DayOfWeek.TUESDAY]: 'We wtorek',
    [DayOfWeek.WEDNESDAY]: 'W środę',
    [DayOfWeek.THURSDAY]: 'W czwartek',
    [DayOfWeek.FRIDAY]: 'W piątek',
    [DayOfWeek.SATURDAY]: 'W sobotę',
    [DayOfWeek.SUNDAY]: 'W niedzielę'
  };

  const workWeekDays: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY
  ];

  const weekDays: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY
  ];

  const normalizedDays = Array.isArray(days)
  ? days.map(offerDay => offerDay.days_offer)
  : [days];

  // Rozwijanie specjalnych wartości
  const expandedDays = normalizedDays.flatMap(day => {
    switch (day) {
      case 'WORK_WEEK':
        return workWeekDays;
      case 'WEEK':
        return weekDays;
      default:
        return [day];
    }
  });

  // Usuwanie duplikatów poprzez filter
  const uniqueDays = expandedDays.filter(
    (day, index, self) => self.indexOf(day) === index
  );

  // Mapowanie na obiekty z labelami
  return uniqueDays.map(day => ({
    value: day,
    label: dayLabels[day as DayOfWeek]
  }));
}



  // Konwersja offer_merchant_points do tablicy liczb
  const merchantPoints = Array.isArray(offerData.offer_merchant_points)
    ? offerData.offer_merchant_points.map(point =>
        typeof point === 'object' ? point.id : point
      )
    : offerData.offer_merchant_points;

  const localization = Array.isArray(offerData.offer_localizations)
    ? {
        coordinates: {
            lat: Number(offerData.offer_localizations[0].latitude),
            lng: Number(offerData.offer_localizations[0].longitude),
        },
        offer_range: offerData.offer_localizations[0].offer_range,
        place_name: offerData.offer_localizations[0].place_name,
      }
      : offerData.offer_localizations;

  const user_changes = offerData.offer_user_changes.map(change => ({
      id: change.user_id,
      status: change.user_status,
  }))

  return {
      step1: {
          clients: offerData.clients,
          target_group: offerData.target_group,
          offer_localizations: localization,
          estimated_cost: offerData.estimated_cost,
          number_of_customers: offerData.number_of_customers,
          extra_customers: offerData.extra_customers,
          offer_user_changes: user_changes,
      },
      step2: {
          name: offerData.name,
          type: offerData.type,
          extra_cashback: offerData.extra_cashback,
          offer_merchant_points: merchantPoints,
          start_date: offerData.start_date,
          end_date: offerData.end_date,
          offer_days: convertedOfferDays(offerData.offer_days),
          usage: offerData.usage,
          description: offerData.description,
          direct_addressing: offerData.direct_addressing,
          direct_addressing_style: offerData.direct_addressing_style,
          send_push_notification: offerData.send_push_notification,
      },
      step3: {
          image_path: offerData.image_path,
          film_path: offerData.film_path,
          film_title: offerData.film_title,
          film_layout: offerData.film_layout,
          film_file: offerData.film_file,
      }
  };
}

export const estimateCost = (
    clients: string,
    numberOfCustomers: number,
    added_customers: number,
    customersCost: CustomersCost|null,
    ): number => {
        if (numberOfCustomers && customersCost && clients === "current") {
            const baseCustomers = numberOfCustomers - added_customers;
            const totalCost = ((baseCustomers * customersCost.current_customer) +
                             (added_customers * customersCost.added_customer));
            return Number(totalCost.toFixed(2));
        } else if (numberOfCustomers && customersCost && clients === "new") {
            return Number((numberOfCustomers * customersCost.new_customer).toFixed(2));
        } else {
            return 0;
        }
    }