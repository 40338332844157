import React from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import Button from "../common/Button";
import {useSelector} from "react-redux";
import {getCurrentOffer} from "../../redux/selectors/merchantSelector";
import {OfferData} from "../../services/interfaces";
import {updateOfferHandler} from "../../handlers/offerHandlers";

interface EndOfferModalProps {
    isOpen: boolean,
    onClose: () => void;
    offer?: OfferData;
}

const EndOfferModal = ({
                           isOpen,
                           onClose,
                           offer,
                       }: EndOfferModalProps) => {
    const currentOffer = useSelector(getCurrentOffer);

    const updateCurrentOfferEndDate = (offer: OfferData) => {
        const today = new Date();
        today.setMinutes(today.getMinutes() - today.getTimezoneOffset());

        const newOffer = {...offer, is_over: true, end_date: today}
        updateOfferHandler(offer, newOffer);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="end-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <img className="icon" src={icons.redWarningIcon} alt="warning"/>
                    <div className="title-wrapper">
                        <h2 className="modal-title">Jesteś pewien?</h2>
                    </div>
                </div>
                <div className="modal-body">
                    <p>Jeżeli zakończysz promocję, to przestanie ona już być widoczna dla użytkowników.</p>
                    <div className="button-wrapper">
                        <Button label="Anuluj"
                                onClick={onClose}
                                type="Default"
                        />
                        <Button label="Tak, zakończ"
                                onClick={() => {
                                    if (offer) {
                                        updateCurrentOfferEndDate(offer)
                                    } else {
                                        updateCurrentOfferEndDate(currentOffer)
                                    }
                                    onClose()
                                }}
                                type="Red"
                                extraStyle={{
                                    lineHeight: 1
                                }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EndOfferModal;