import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {icons} from "../../../services/images";
import FiltersDropdown from "./FiltersDropdown";
import FiltersDatePicker from "./FiltersDatePicker";
import {CupActionFilterOptions, FilterModalProps, MultiSelectOption} from "../../../services/interfaces";
import Button from "../../common/Button";
import {useSelector} from "react-redux";
import {getCurrentMerchantPoint, getIsMerchantSelected, getMerchant} from "../../../redux/selectors/merchantSelector";
import {getCupActionFilters} from "../../../redux/selectors/cupSelector";
import {getCupActionsFilterOptionsHandler} from "../../../handlers/cupHandlers";
import {CupActionTypeEnum} from "../../../services/dictionaries/enums";
import {getFiltersClientOptions, getFiltersPointsOptions} from "../../../services/helpers";


const CupActionsFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: CupActionFilterOptions | null = useSelector(getCupActionFilters);

    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    useEffect(() => {
        getCupActionsFilterOptionsHandler()
    }, [isMerchantSelected, merchant, merchantPoint]);

    const [clients, setClients] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [points, setPoints] = useState<string[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const columnsOptions: MultiSelectOption[] = [
        {value: 'ID klienta', children: 'user_id'},
        {value: 'ID akcji', children: 'id'},
        {value: 'Data', children: 'date'},
        {value: 'Adres punktu', children: 'address'},
        {value: 'Rodzaj Akcji', children: 'type'},
        {value: 'Liczba kubków', children: 'number_of_cups'},
        {value: 'Stan magazynowy', children: 'cup_state'},
    ];

    const clientOptions = getFiltersClientOptions(filterOptions);

    const pointsOptions: MultiSelectOption[] = getFiltersPointsOptions(filterOptions);

    const actionsOptions = Object.entries(CupActionTypeEnum).map(([key, value]) => ({
        value: value,
        children: key
    }));

    const clearFilters = () => {
        setClients([]);
        setColumns([]);
        setPoints([]);
        setTypes([]);
        setFromDate(null);
        setToDate(null);
    };

    const applyFilters = () => {

        const urlFilters = '' +
            'clients=' + clients.join(',') +
            '&columns=' + columns.join(',') +
            '&points=' + points.join(',') +
            '&types=' + types.join(',') +
            '&fromDate=' + (fromDate ? fromDate.toISOString() : '') +
            '&toDate=' + (toDate ? toDate.toISOString() : '');
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Wybierz klienta"
                    placeholder="Wybierz klienta po user ID..."
                    options={clientOptions}
                    values={clients}
                    setValues={setClients}
                />
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                {filterOptions && Object.keys(filterOptions.merchant_points).length > 0 &&
                    <FiltersDropdown
                        title="Wybierz punkty"
                        placeholder="Zaznacz punkty które chcesz wyświetlać..."
                        options={pointsOptions}
                        values={points}
                        setValues={setPoints}
                        setChildren={true}
                    />
                }
                <FiltersDropdown
                    title="Rodzaj akcji"
                    placeholder="Zaznacz rodzaje akcji które chcesz wyświetlać..."
                    options={actionsOptions}
                    values={types}
                    setValues={setTypes}
                    setChildren={true}
                />
                <FiltersDatePicker
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />
            </div>
            <div className="modal-footer">
                {filterOptions && <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default CupActionsFiltersModal;