import {OfferData} from "../../services/interfaces";
import {useSelector} from "react-redux";
import {getCurrentOffer, getOfferDashboard} from "../../redux/selectors/merchantSelector";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {Tooltip} from "react-tooltip";
import {icons} from "../../services/images";
import Button from "../common/Button";
import {formatNumber} from "../../services/numbers";
import {updateOfferHandler} from "../../handlers/offerHandlers";
import UseOfferDepositModal from "./UseOfferDepositModal";

interface PayOfferModalProps {
    isOpen: boolean,
    onClose: () => void;
    offer?: OfferData;
}

const PayOfferModal = ({
                            isOpen,
                            onClose,
                            offer,
                        }: PayOfferModalProps) => {

    const currentOffer = useSelector(getCurrentOffer);
    const offerDashboard = useSelector(getOfferDashboard);

    const currentUsersRate = 0.15; //should be downloaded from AdminAPI
    const newUsersRate = 0.2;

    const [number, setNumber] = useState<number | undefined>(undefined);
    const [cost, setCost] = useState<number>(0);
    const [offerUsers, setOfferUsers] = useState<string>(currentOffer.clients);
    const [offerRate, setOfferRate] = useState<number>(currentUsersRate);
    const [depositModalIsOpen, setDepositModalIsOpen] = useState<boolean>(false);
    const [takeDeposit, setTakeDeposit] = useState<boolean>(false);


    useEffect(() => {
            if(offer){
                if(offer.clients === "current"){
                    setOfferRate(currentUsersRate);
                }
                if(offer.clients === "new"){
                    setOfferRate(newUsersRate);
                }
            } else{
                if(currentOffer.clients === "current"){
                    setOfferRate(currentUsersRate);
                }
                if(currentOffer.clients === "new"){
                    setOfferRate(newUsersRate);
                }
            }
        },[currentOffer,offer]);

    const updateOfferDeposit = (offer: OfferData) => {
        let newNumber = 0;
        if(number) newNumber = number + offer.number_of_customers;
        const newOffer = {...offer, use_deposits: takeDeposit, offer_rate: offerRate, estimated_cost: cost, number_of_customers: newNumber}
        updateOfferHandler(offer, newOffer);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="pay-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title" onClick={()=>setDepositModalIsOpen(true)}>Opłać promocję</h5>
                        <div className="icon-wrapper">
                            <Tooltip id="pay offer" className="tooltip" noArrow={true}>
                                <p className="tooltip-body">Tekst tooltip dotyczy opłacenia promocji.</p>
                            </Tooltip>
                            <img src={icons.infoIcon} className="info-icon" alt="info" data-tooltip-id="pay offer"
                                 data-tooltip-place="top"/>
                        </div>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="section">
                        <p className="label label-margin">Liczba osób do których chcesz dotrzeć:</p>
                        <div className="input">
                            <input type="number"
                                   className="input-text"
                                   value={number}
                                       onChange={e => {
                                           setNumber(Number(e.target.value));
                                           if(offerUsers === "current"){
                                               setCost(Number(e.target.value)*currentUsersRate)
                                           }
                                           if(offerUsers === "new"){
                                               setCost(Number(e.target.value)*newUsersRate)
                                           }
                                           setTakeDeposit(false)
                                       }}
                                       placeholder="Podaj liczbę osób do których chcesz dotrzeć..."
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="offer-wrapper with-border"></div>
                            <div className="offer-wrapper">
                                <span className="label">Łączny koszt promocji: </span>
                                <span className="label w700">{formatNumber(cost)}</span>
                            </div>
                        {takeDeposit &&
                            <>
                                <div className="offer-wrapper">
                                    <span className="label">Depozyt promocyjny: </span>
                                    <span className="label w700">{`- ${formatNumber(offerDashboard.offers_deposit)}`}</span>
                                </div>
                                <div className="offer-wrapper">
                                    <span className="label blue">Do zapłaty: </span>
                                    <span className="label w700 blue">{formatNumber(cost - offerDashboard.offers_deposit)}</span>
                                </div>
                            </>
                        }
                    </div>
                    <Button label={`Zapłać ${takeDeposit ? formatNumber(cost - offerDashboard.offers_deposit) : formatNumber(cost)}`}
                            fullWidth
                            onClick={() => {
                                if (!takeDeposit && cost >= offerDashboard.offers_deposit && offerDashboard.offers_deposit){
                                        setDepositModalIsOpen(true);
                                        return
                                    }
                                    if (offer) {
                                        updateOfferDeposit(offer)
                                    } else {
                                        updateOfferDeposit(currentOffer)
                                    }
                                    setTakeDeposit(false)
                                    onClose()
                                }}
                                type="Primary"
                        />
                    </div>
                <UseOfferDepositModal isOpen={depositModalIsOpen} takeDeposit={()=>setTakeDeposit(true)} onClose={()=>setDepositModalIsOpen(false)}/>
                </div>
        </Modal>
);
};

export default PayOfferModal;