import {ChartInterval} from "../../../services/interfaces";
import {Select} from "antd";
import {Tooltip} from "react-tooltip";
import {icons} from "../../../services/images";
import React from "react";


interface Props {
    chartInterval: ChartInterval;
    onIntervalChange: (value: ChartInterval) => void;
}


const ChartIntervalSelect = ({chartInterval, onIntervalChange}: Props) => {
    return (
        <div className="chart-interval-select">
            <div className="label-wrapper">
                <span className="label-2">Interwał czasowy: </span>
                <Tooltip id="ChartIntervalTooltip" className="tooltip" noArrow={true}>
                    <p className="tooltip-title">Interwał czasowy</p>
                    <p className="tooltip-body">Wykres umożliwia podgląd danych w różnych interwałach czasowych. Dla każdego wybranego interwału system porówna dane z poprzedniego okresu o takiej samej długości.</p>
                </Tooltip>
                <img src={icons.infoGrayIcon} alt="info" data-tooltip-id="ChartIntervalTooltip"
                     data-tooltip-place="top-end"/>
            </div>
            <Select
                value={chartInterval}
                onChange={onIntervalChange}
                options={[
                    {value: "DAY" as ChartInterval, label: 'Dzień'},
                    {value: "LAST_7_DAYS" as ChartInterval, label: 'Ostatnie 7 dni'},
                    {value: "LAST_30_DAYS" as ChartInterval, label: 'Ostatnie 30 dni'},
                    {value: "LAST_90_DAYS" as ChartInterval, label: 'Ostatnie 90 dni'},
                    {value: "LAST_365_DAYS" as ChartInterval, label: 'Ostatnie 365 dni'},
                    {value: "WEEK" as ChartInterval, label: 'Tydzień'},
                    {value: "MONTH" as ChartInterval, label: 'Miesiąc'},
                    {value: "QUARTER" as ChartInterval, label: 'Kwartał'},
                    {value: "YEAR" as ChartInterval, label: 'Rok'},
                ]}
            />
        </div>
    )
};

export default ChartIntervalSelect;