import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ChartData,
    ChartInterval,
    DashboardData, DatePreset,
    DateRange, Gender,
    HistoryFilterOptions,
    HistoryTransactionDashboardResponse,
    HistoryTransactionResponse, MasterCashbackLevel,
    Merchant, MerchantPoint,
    OfferData, OfferDays, OfferLocation, OfferType, OfferUsage, OfferClients,
    PreviousPeriod,
    RangeBase,
    TargetGroup, TargetGroupRangeKey,
    Transaction, TransactionReturn, VideoLayout, OfferDashboardData
} from "../services/interfaces";
import {omit, uniqueId} from "lodash";
import {DayOfWeek} from "../services/dictionaries/enums";
import {DEFAULT_END_DATE, DEFAULT_PRESET, DEFAULT_START_DATE} from "../services/dates";
import {DEFAULT_OFFER_SEND_LOCATION} from "../components/modals/MapModal";

export interface MerchantState {
    barChartSelected: boolean
    chartInterval: ChartInterval,
    currentTargetGroup: TargetGroup,
    currentOffer: OfferData,
    dashboard: {
        previous: DashboardData,
        current: DashboardData,
    }
    dateRange: DateRange,
    datePreset: DatePreset | null,
    firstTransactionDate: string | null
    historyFilterOptions: HistoryFilterOptions | null
    isMerchantSelected: boolean,
    masterCashbackLevels: MasterCashbackLevel[],
    merchant: Merchant | null,
    merchantPoint: MerchantPoint,
    previousPeriod: PreviousPeriod,
    returnMatches: TransactionReturn[],
    returns: Transaction[]
    targetGroups: TargetGroup[]
    offers: OfferData[],
    offerDashboard: OfferDashboardData,
    transactionsHistory: {
        maxPage: number
        pageSize: number
        ref_refunds: { [id: number]: Transaction[] }
        transactions: Transaction[]
        transactionsCount: number
    }
    transactionsHistoryDashboard: {
        transactions: ChartData[]
        transactionsCount: number
        transactionsPrevious: ChartData[]
        transactionsPreviousCount: number
    }
}

export const MERCHANT_POINT_DEFAULT_STATE: MerchantPoint = {
    address: '',
    address_color: 1,
    background_color: 1,
    background_image: '',
    cashback: 1.0,
    cup_count: 0,
    latitude: '',
    logo_image: '',
    longitude: '',
    name: '',
    opening_hours: [
        {day_of_week: DayOfWeek.MONDAY, open_time: '', close_time: ''},
        {day_of_week: DayOfWeek.TUESDAY, open_time: '', close_time: ''},
        {day_of_week: DayOfWeek.WEDNESDAY, open_time: '', close_time: ''},
        {day_of_week: DayOfWeek.THURSDAY, open_time: '', close_time: ''},
        {day_of_week: DayOfWeek.FRIDAY, open_time: "", close_time: ''},
        {day_of_week: DayOfWeek.SATURDAY, open_time: '', close_time: ''},
        {day_of_week: DayOfWeek.SUNDAY, open_time: '', close_time: ''},
    ],
    place_id: '',
    place_name: '',
    rating: null,
    rating_count: null,
    service_types: [],
    terminals: [],
    waycup_partner: false,
    waycup_point: false,
    phone: '',
};


export const TARGET_GROUP_DEFAULT_STATE = {
    name: '',
    age_ranges: [
        {"min": 0, "max": 20, "selected": false, id: uniqueId('temp_')},
        {"min": 21, "max": 40, "selected": false, id: uniqueId('temp_')},
        {"min": 41, "max": 60, "selected": false, id: uniqueId('temp_')},
        {"min": 61, "max": null, "selected": false, id: uniqueId('temp_')},
        {"min": null, "max": null, "selected": false, id: uniqueId('temp_')},
    ],
    gender: [],
    amount_ranges: [
        {"min": 0, "max": 100, "selected": false, id: uniqueId('temp_')},
        {"min": 101, "max": 300, "selected": false, id: uniqueId('temp_')},
        {"min": 301, "max": 600, "selected": false, id: uniqueId('temp_')},
        {"min": 601, "max": 900, "selected": false, id: uniqueId('temp_')},
        {"min": 901, "max": null, "selected": false, id: uniqueId('temp_')}
    ],
    transactions_ranges: [
        {"min": 1, "max": 1, "selected": false, id: uniqueId('temp_')},
        {"min": 2, "max": 5, "selected": false, id: uniqueId('temp_')},
        {"min": 6, "max": 10, "selected": false, id: uniqueId('temp_')},
        {"min": 11, "max": 20, "selected": false, id: uniqueId('temp_')},
        {"min": 21, "max": null, "selected": false, id: uniqueId('temp_')}
    ],
    amount_sum_ranges: [
        {"min": 0, "max": 500, "selected": false, id: uniqueId('temp_')},
        {"min": 501, "max": 1000, "selected": false, id: uniqueId('temp_')},
        {"min": 1001, "max": 1500, "selected": false, id: uniqueId('temp_')},
        {"min": 1501, "max": 2000, "selected": false, id: uniqueId('temp_')},
        {"min": 2001, "max": null, "selected": false, id: uniqueId('temp_')}
    ],
    start_date: DEFAULT_START_DATE,
    end_date: DEFAULT_END_DATE,
    is_offer_duplicate: false,
};

export const TARGET_GROUP_RANGE_KEYS: TargetGroupRangeKey[] = ['age_ranges', 'amount_ranges', 'transactions_ranges', 'amount_sum_ranges'];

export const DEFAULT_OFFER_DATA = {
    clients: "current" as OfferClients,
    target_group: TARGET_GROUP_DEFAULT_STATE,
    offer_localizations: [DEFAULT_OFFER_SEND_LOCATION],
    estimated_cost: 0,
    number_of_customers: 0,
    extra_customers: 0,
    offer_user_changes: [],
    offer_rate: 0,
    name: "",
    type: undefined,
    extra_cashback: 0,
    offer_merchant_points: [],
    start_date: null,
    end_date: null,
    offer_days: [],
    usage: "one_time" as OfferUsage,
    description: "",
    direct_addressing: false,
    direct_addressing_style: "Hej Adam,...",
    send_push_notification: false,
    is_over: false,
    empty_deposit: false,
    use_deposits: false,
    image_path: "",
    film_path: "",
    film_title: "",
    film_layout: "horizontal" as VideoLayout,
    film_file: null,
}

const DEFAULT_STATE: MerchantState = {
    datePreset: DEFAULT_PRESET,
    barChartSelected: true,
    chartInterval: "DAY",
    currentTargetGroup: TARGET_GROUP_DEFAULT_STATE,
    currentOffer: DEFAULT_OFFER_DATA,
    dashboard: {
        current: {
            income: 0,
            income_data: [],
            transaction_count: 0,
            returning_customer_count: 0,
            new_customer_count: 0,
            cup_use_count: 0,
            cup_collected_count: 0,
            cup_returned_count: 0,
        },
        previous: {
            income: 0,
            income_data: [],
            transaction_count: 0,
            returning_customer_count: 0,
            new_customer_count: 0,
            cup_use_count: 0,
            cup_collected_count: 0,
            cup_returned_count: 0,
        },
    },
    dateRange: {
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE
    },

    firstTransactionDate: null,
    historyFilterOptions: null,
    isMerchantSelected: true,
    masterCashbackLevels: [],
    merchant: null,
    merchantPoint: MERCHANT_POINT_DEFAULT_STATE,
    previousPeriod: "period",
    returnMatches: [],
    returns: [],
    targetGroups: [],
    offers: [],
    offerDashboard: {
        ended_offers: 0,
        future_offers: 0,
        offers_deposit: 0,
        current_offer_income: 0,
        new_clients: [],
        returning_clients: [],
        income_data: [],
        income_sum: 0,
        prev_income_data: [],
        prev_income_sum: 0,
        offer_use_count: [],
        offer_reservation_count: [],
        offer_realization_count: [],
        offer_current_cost: 0,
        offer_current_range: 0,
    },
    transactionsHistory: {
        maxPage: 0,
        pageSize: 0,
        ref_refunds: {},
        transactions: [],
        transactionsCount: 0
    },
    transactionsHistoryDashboard: {
        transactions: [],
        transactionsCount: 0,
        transactionsPrevious: [],
        transactionsPreviousCount: 0
    }
};

const merchantSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'merchant',
    reducers: {
        importMerchantPoint: (state, action: PayloadAction<MerchantPoint>) => {
            return {
                ...state,
                merchantPoint: {
                    ...MERCHANT_POINT_DEFAULT_STATE,
                    ...omit(action.payload, ['id', 'name', 'address', 'place_id', 'place_name', 'longitude', 'latitude', 'rating', 'rating_count']),
                    opening_hours: action.payload.opening_hours.map(o => (omit(o, 'id'))),
                    service_types: action.payload.service_types.map(s => ({...s, id: uniqueId('new_')})),
                },
            }
        },
        resetMerchantPoint: (state) => {
            return {
                ...state,
                merchantPoint: {
                    ...MERCHANT_POINT_DEFAULT_STATE
                }
            }
        },
        resetMerchantState: (state) => {
            return {...state, ...DEFAULT_STATE}
        },
        setDashboardData: (state, action: PayloadAction<{ previous: DashboardData, current: DashboardData }>) => {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    current: action.payload.current,
                    previous: action.payload.previous,
                },
            }
        },
        setDateRange: (state, action: PayloadAction<DateRange>) => {
            return {
                ...state,
                dateRange: action.payload
            }
        },
        setDatePreset: (state, action: PayloadAction<DatePreset | null>) => {
            return {
                ...state,
                datePreset: action.payload
            }
        },
        setChartInterval: (state, action: PayloadAction<ChartInterval>) => {
            return {
                ...state,
                chartInterval: action.payload
            }
        },
        setHistoryFilterOptionsAction: (state, action: PayloadAction<HistoryFilterOptions>) => {
            return {
                ...state,
                historyFilterOptions: action.payload
            }
        },
        setHistoryTransactionDashboard: (state, action: PayloadAction<HistoryTransactionDashboardResponse>) => {
            return {
                ...state,
                transactionsHistoryDashboard: {
                    transactions: action.payload.transactions,
                    transactionsCount: action.payload.transactions_count,
                    transactionsPrevious: action.payload.transactions_previous,
                    transactionsPreviousCount: action.payload.transactions_previous_count
                },
            }
        },
        setHistoryTransactions: (state, action: PayloadAction<HistoryTransactionResponse>) => {
            return {
                ...state,
                transactionsHistory: {
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    ref_refunds: action.payload.ref_refunds,
                    transactions: action.payload.transactions,
                    transactionsCount: action.payload.transactions_count
                },
            }
        },
        setIsMerchantSelected: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isMerchantSelected: action.payload
            }
        },
        setMerchant: (state, action: PayloadAction<Merchant>) => {
            return {
                ...state,
                merchant: action.payload,
            }
        },
        setMerchantPoint: (state, action: PayloadAction<MerchantPoint>) => {
            return {
                ...state,
                merchantPoint: action.payload,
            }
        },
        setTargetGroups: (state, action: PayloadAction<TargetGroup[]>) => {
            return {
                ...state,
                targetGroups: action.payload,
            }
        },
        setCurrentTargetGroup: (state, action: PayloadAction<TargetGroup>) => {
            return {
                ...state,
                currentTargetGroup: action.payload,
            }
        },
        changeCurrentTargetGroup: (state, action: PayloadAction<{
            field: string,
            value: string | RangeBase[] | Gender[]
        }>) => {
            return {
                ...state,
                currentTargetGroup: {
                    ...state.currentTargetGroup,
                    [action.payload.field]: action.payload.value
                },
            }
        },
        selectTargetGroupRange: (state, action: PayloadAction<{
            targetGroupRangeKey: string,
            id: number | string
        }>) => {
            const oldRanges = state.currentTargetGroup?.[action.payload.targetGroupRangeKey] as RangeBase[];
            const newRanges = oldRanges.map(entry => entry.id === action.payload.id ? {
                ...entry,
                selected: !entry.selected
            } : entry);

            return {
                ...state,
                currentTargetGroup: {
                    ...state.currentTargetGroup,
                    [action.payload.targetGroupRangeKey]: newRanges
                },
            }
        },
        resetCurrentTargetGroup: (state) => {
            return {
                ...state,
                currentTargetGroup: TARGET_GROUP_DEFAULT_STATE,
            }
        },
        setCurrentOffer: (state, action: PayloadAction<OfferData>) => {
            return {
                ...state,
                currentOffer: action.payload,
            }
        },
        changeCurrentOffer: (state, action: PayloadAction<{
            field: string,
            value: string | number | boolean | number [] | Date | null | TargetGroup | OfferLocation | OfferType
                | OfferDays[] | File
        }>) => {
            return {
                ...state,
                currentOffer: {
                    ...state.currentOffer,
                    [action.payload.field]: action.payload.value
                },
            }
        },
        resetCurrentOffer: (state) => {
            return {
                ...state,
                currentOffer: DEFAULT_OFFER_DATA,
            }
        },
        setOffers: (state, action: PayloadAction<OfferData[]>) => {
            return {
                ...state,
                offers: action.payload,
            }
        },
        setOffersDashboard: (state, action: PayloadAction<OfferDashboardData>) => {
            return {
                ...state,
                offerDashboard: action.payload,
            }
        },
        addToOffers: (state, action: PayloadAction<OfferData>) => {
            return {
                ...state,
                offers: [...state.offers, action.payload]
            }
        },
        setFirstTransactionDate: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                firstTransactionDate: action.payload,
            }
        },
        setPreviousPeriod: (state, action: PayloadAction<PreviousPeriod>) => {
            return {
                ...state,
                previousPeriod: action.payload
            }
        },
        setBarChartSelected: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                barChartSelected: action.payload
            }
        },
        setMasterCashbackLevels: (state, action: PayloadAction<MasterCashbackLevel[]>) => {
            return {
                ...state,
                masterCashbackLevels: action.payload
            }
        },
        setMerchantDeposit: (state, action: PayloadAction<{ deposit: number, deposit_requirement: number }>) => {
            const merchant = state.merchant;
            if (!merchant) return state;
            return {
                ...state,
                merchant: {
                    ...merchant,
                    deposit: action.payload.deposit,
                    deposit_requirement: action.payload.deposit_requirement
                }
            }
        },
        setMerchantReturns: (state, action: PayloadAction<Transaction[]>) => {
            return {
                ...state,
                returns: action.payload
            }
        },
        setReturnMatches: (state, action: PayloadAction<TransactionReturn[]>) => {
            return {
                ...state,
                returnMatches: action.payload
            }
        }
    }
});

export default merchantSlice.reducer;

export const setMerchantAction = merchantSlice.actions.setMerchant;
export const setMerchantPointAction = merchantSlice.actions.setMerchantPoint;
export const importMerchantPointAction = merchantSlice.actions.importMerchantPoint;
export const setDashboardDataAction = merchantSlice.actions.setDashboardData;
export const setDateRangeAction = merchantSlice.actions.setDateRange;
export const setDatePresetAction = merchantSlice.actions.setDatePreset;
export const setChartIntervalAction = merchantSlice.actions.setChartInterval;
export const setIsMerchantSelectedAction = merchantSlice.actions.setIsMerchantSelected;
export const resetMerchantPointAction = merchantSlice.actions.resetMerchantPoint;
export const resetMerchantStateAction = merchantSlice.actions.resetMerchantState;
export const setHistoryTransactionsAction = merchantSlice.actions.setHistoryTransactions;
export const setHistoryTransactionDashboardAction = merchantSlice.actions.setHistoryTransactionDashboard;
export const setHistoryFilterOptionsAction = merchantSlice.actions.setHistoryFilterOptionsAction;
export const setTargetGroupsAction = merchantSlice.actions.setTargetGroups;
export const setCurrentTargetGroupAction = merchantSlice.actions.setCurrentTargetGroup;
export const changeCurrentTargetGroupAction = merchantSlice.actions.changeCurrentTargetGroup;
export const selectTargetGroupRangeAction = merchantSlice.actions.selectTargetGroupRange;
export const resetCurrentTargetGroupAction = merchantSlice.actions.resetCurrentTargetGroup;
export const setOffersAction = merchantSlice.actions.setOffers;
export const addToOffersAction = merchantSlice.actions.addToOffers;
export const setCurrentOfferAction = merchantSlice.actions.setCurrentOffer;
export const setOfferDashboardAction = merchantSlice.actions.setOffersDashboard;
export const changeCurrentOfferAction = merchantSlice.actions.changeCurrentOffer;
export const resetCurrentOfferAction = merchantSlice.actions.resetCurrentOffer;
export const setFirstTransactionDateAction = merchantSlice.actions.setFirstTransactionDate;
export const setPreviousPeriodAction = merchantSlice.actions.setPreviousPeriod;
export const setBarChartSelectedAction = merchantSlice.actions.setBarChartSelected;
export const setMasterCashbackLevelsAction = merchantSlice.actions.setMasterCashbackLevels;
export const setMerchantDepositAction = merchantSlice.actions.setMerchantDeposit;
export const setMerchantReturnsAction = merchantSlice.actions.setMerchantReturns;
export const setReturnMatchesAction = merchantSlice.actions.setReturnMatches;