import React, {useEffect} from "react";
import {icons} from "../../services/images";
import Modal from "react-modal";
import Stepper from "./Stepper";
import {OfferData} from "../../services/interfaces";
import {store} from "../../redux/store";
import {setCurrentOfferAction} from "../../redux/merchant";

interface EditOfferModalProps {
    isOpen: boolean;
    onClose: () => void;
    offer: OfferData;
}

const EditOfferModal = ({isOpen, onClose, offer}: EditOfferModalProps) => {
    useEffect(() => {
        if (offer) store.dispatch(setCurrentOfferAction(offer))
    }, [offer]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                overlayClassName="modal-wrapper"
                ariaHideApp={false}
                className="stepper-modal-content filters-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Edytuj promocję</h5>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <Stepper offer={offer} closeModal={onClose} edit={true}/>
            </Modal>
        </>
    )
};

export default EditOfferModal;