import React, {useState} from 'react';
import Button from "../../common/Button";
import dayjs from "dayjs";
import {icons} from "../../../services/images";
import PreviewModal from "../PreviewModal";
import {OfferTypeMapping, OfferUsageMapping} from "../../../services/dictionaries/enums";
import {showCustomersNumber} from "../../../services/utils";
import {formatNumber} from "../../../services/numbers";
import {CustomersCost, StepperOfferData} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {
    getCurrentOffer,
    getOfferDashboard,
} from "../../../redux/selectors/merchantSelector";
import {
    createOfferHandler,
    updateSendOfferHandler
} from "../../../handlers/offerHandlers";
import {
    convertOfferToOfferSendData,
    convertStepperOfferToOfferSendData,
    convertStepperOfferToSendData,
    showOfferDays
} from "../../../services/offerUtils";
import UseOfferDepositModal from "../UseOfferDepositModal";
import {Tooltip} from "react-tooltip";
import {createTargetGroupHandler, updateTargetGroupHandler} from "../../../handlers/targetGroupHandlers";

dayjs.locale('pl');

interface StepProps {
    formData: StepperOfferData;
    handleChange: (field: string, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
    setStep: (value: number) => void;
    isEditing: boolean;
    numberOfCustomers: number;
    estimateCost: number;
    oldCost: number;
    customersCost: CustomersCost|null;
    currentTargetGroup: string;
    overrideTargetGroup: boolean;
}

const Step4: React.FC<StepProps> = ({
                                        formData,
                                        numberOfCustomers,
                                        estimateCost,
                                        prevStep,
                                        nextStep,
                                        setStep,
                                        isEditing,
                                        oldCost,
                                        customersCost,
                                        currentTargetGroup,
                                        overrideTargetGroup,
                                    }) => {
    const [previewIsOpen, setPreviewIsOpen] = useState(false);
    const [takeDeposit, setTakeDeposit] = useState<boolean>(false);
    const [depositModalIsOpen, setDepositModalIsOpen] = useState<boolean>(false);
    const [firstCheck, setFirstCheck] = useState<boolean>(false);
    const offerDashboard = useSelector(getOfferDashboard);
    const currentOffer = useSelector(getCurrentOffer);

    const buttonLabel = (isEditing: boolean) => {
        if(isEditing && oldCost < estimateCost){
            return 'Zapłać i zmodyfikuj promocję';
        }
        if(isEditing && oldCost === estimateCost){
            return 'Zapisz i zmodyfikuj promocję';
        }
        return 'Zapłać i utwórz promocję';
    }

    const translateClientValue = (value: string): string =>{
        if(value === "current"){
            return "Obecnych klinetów"
        }
        if( value === "new"){
            return "Nowy klientów"
        }
        return ""
    }

    const onSave = () => {
        if (isEditing) {
            updateSendOfferHandler(
                convertOfferToOfferSendData(currentOffer),
                convertStepperOfferToOfferSendData(formData, takeDeposit)
            );
        } else {
            if (currentTargetGroup === "needToSave") {
                const {id, ...restTargetGroup} = formData.step1.target_group;
                createTargetGroupHandler(restTargetGroup, () => {
                }, () => {
                }, true);
            }
            if (currentTargetGroup === "needToUpdate" && overrideTargetGroup) {
                updateTargetGroupHandler(
                    currentOffer.target_group,
                    formData.step1.target_group,
                    () => {},
                    () => {},
                    true);
            }
            createOfferHandler(convertStepperOfferToSendData(formData, takeDeposit));
        }
        nextStep();
    };

    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <div className="preview-wrapper"
                         onClick={() => {
                             setPreviewIsOpen(true)
                         }}
                    >
                        <img className="preview-pin"
                             src={icons.previewIcon} alt={'Preview'}/>
                        <p className="label required-mark ml26">Podgląd widoku</p>
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Promocja przeznaczona dla:</p>
                        <p className="label required-mark pointer" onClick={() => setStep(1)}>Edytuj</p>
                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={translateClientValue(formData.step1.clients)}
                               disabled
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Grupa docelowa:</p>
                        <p className="label required-mark pointer" onClick={() => setStep(1)}>Edytuj</p>
                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               disabled
                               value={formData.step1.target_group.name}
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Szczegóły promocji:</p>
                        <p className="label required-mark pointer"
                           onClick={() => setStep(2)}>Edytuj</p>
                    </div>
                    <div className="details-wrapper">
                        <div className="label-space-wrapper mb12">
                            <p className="label text-gray w700">
                                Nazwa:
                            </p>
                            <p className="label text-gray w700">
                                {formData.step2.name}
                            </p>
                        </div>
                        <div className="label-space-wrapper mb12">
                            <p className="label text-gray w400">Rodzaj:</p>
                            <p className="label text-gray w400">
                                {formData.step2.type && OfferTypeMapping[formData.step2.type]}
                            </p>
                        </div>
                        <div className="label-space-wrapper mb12">
                            <>
                                <p className="label text-gray w400">Czas trwania:</p>
                                <p className="label text-gray w400">
                                    {`${dayjs(formData.step2.start_date).format('DD.MM.YYYY')} - 
                                    ${dayjs(formData.step2.end_date).format('DD.MM.YYYY')}`}</p>
                            </>
                        </div>
                        <div className="label-space-wrapper mb12">
                            <p className="label text-gray w400">Obowiązuje w:</p>
                            <p className="label text-gray w400">
                                {showOfferDays(formData.step2.offer_days)}</p>
                        </div>
                        <div className="label-space-wrapper mb12">
                            <p className="label text-gray w400">Liczba dostępnych użyć:</p>
                            <p className="label text-gray w400">{formData.step2.usage && OfferUsageMapping[formData.step2.usage]}</p>
                        </div>
                        <div className="label-space-wrapper">
                            <p className="label text-gray w400">Opis:</p>
                            <p className="label text-gray ellipsis-text w400">
                                {formData.step2.description === "" ? "Brak opisu" : formData.step2.description}</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Media:</p>
                        <p className="label required-mark pointer"
                           onClick={() => setStep(3)}>Edytuj</p>
                    </div>
                    <div className="input label-margin">
                        <input type="text"
                               className="input-text"
                               value={"Obrazek promocyjny"}
                               disabled
                        />
                        {formData.step3.image_path !== "" && <img src={icons.checkIcon} alt={'Check'}/>}

                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={"Film promocyjny"}
                               disabled
                        />
                        {formData.step3.film_path !== "" && <img src={icons.checkIcon} alt={'Check'}/>}
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label w700">{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <div className="wrapper">
                            <span className="label">Łączny koszt promocji: </span>
                            <div className="icon-wrapper">
                               <Tooltip id="customers prize" className="tooltip" noArrow={true}>
                                        {customersCost ?
                                            <>
                                                <p className="tooltip-body">Cena za obecnego
                                                klienta: {formatNumber(customersCost.current_customer)}</p><p
                                                className="tooltip-body">Cena za ręcznie dodanego obecnego
                                                klienta: {formatNumber(customersCost.added_customer)}</p><p
                                                className="tooltip-body">Cena za nowego
                                                klienta: {formatNumber(customersCost.new_customer)}</p>
                                            </>
                                        :
                                            <p className="tooltip-body">Pobieranie aktualnych danych...</p>
                                        }
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="customers prize" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <span
                            className="label w700">{formatNumber(estimateCost)}</span>
                    </div>
                    {isEditing  && oldCost < estimateCost &&
                        <div className="offer-wrapper blue">
                            <span className="label">Do dopłaty: </span>
                            <span
                                className="label w700">{formatNumber(estimateCost - oldCost)}</span>
                        </div>
                    }
                    {takeDeposit &&
                            <>
                                <div className="offer-wrapper">
                                    <span className="label">Depozyt promocyjny: </span>
                                    <span className="label w700">{`- ${formatNumber(offerDashboard.offers_deposit)}`}</span>
                                </div>
                                <div className="offer-wrapper">
                                    <span className="label blue">Do zapłaty: </span>
                                    <span className="label w700 blue">{formatNumber((estimateCost - oldCost) - offerDashboard.offers_deposit)}</span>
                                </div>
                            </>
                        }
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <PreviewModal
                    formData={formData}
                    isOpen={previewIsOpen}
                    isEditing={isEditing}
                    onClose={() => {
                        setPreviewIsOpen(false)
                    }}
                />
                <UseOfferDepositModal
                    isOpen={depositModalIsOpen}
                    nextStep={onSave}
                    takeDeposit={()=>setTakeDeposit(true)}
                    onClose={()=> {
                        setDepositModalIsOpen(false);
                    }}
                />
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label={buttonLabel(isEditing)}
                            onClick={()=> {
                                if (!firstCheck && estimateCost >= offerDashboard.offers_deposit && offerDashboard.offers_deposit) {
                                    setDepositModalIsOpen(true);
                                    setFirstCheck(true);
                                } else {
                                    onSave();
                                }
                            }
                            }
                            extraStyle={{lineHeight: 1}}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step4;