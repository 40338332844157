import {useSelector} from "react-redux";
import {getOfferDashboard} from "../../redux/selectors/merchantSelector";
import Modal from "react-modal";
import {icons} from "../../services/images";
import Button from "../common/Button";
import React from "react";
import {formatNumber} from "../../services/numbers";

interface UseOfferDepositModalProps {
    isOpen: boolean,
    onClose: () => void;
    takeDeposit: ()=> void;
    nextStep?: ()=> void;
}

const UseOfferDepositModal = ({
                            isOpen,
                            onClose,
                            takeDeposit,
                            nextStep,
                        }: UseOfferDepositModalProps) => {
    const offerDashboard = useSelector(getOfferDashboard);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="end-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <img className="icon" src={icons.unblockFundsIcon} alt="warning"/>
                    <div className="title-wrapper">
                        <h2 className="modal-title blue">{formatNumber(offerDashboard.offers_deposit)}</h2>
                    </div>
                </div>
                <div className="modal-body">
                    <p>Masz niewykorzystane środki w depozycie promocyjnym.
                        Czy chcesz przeznaczyć je na opłacenie bieżącej promocji?</p>
                    <div className="button-wrapper">
                        <Button label="Nie, zostaw środki"
                                onClick={()=> {
                                    onClose();
                                    if(nextStep) nextStep();
                                }}
                                type="Default"
                                extraStyle={{
                                    lineHeight: 1
                                }}
                        />
                        <Button label="Tak, użyj środków"
                                onClick={() => {
                                    takeDeposit()
                                    onClose()
                                }}
                                type="Primary"
                                extraStyle={{
                                    lineHeight: 1
                                }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UseOfferDepositModal;