import React, {useEffect, useRef, useState} from 'react';
import Button from "../../common/Button";
import {
    AdditionalSendCustomer, Coordinates, Customer, CustomersCost, Gender, OfferLocation, TargetGroup
} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {
    getOfferDashboard,
    getTargetGroups
} from "../../../redux/selectors/merchantSelector";
import MapModal, {DEFAULT_OFFER_LOCATION} from "../MapModal";
import {icons} from "../../../services/images";
import {Divider, Input as InputAntd, Select, Space} from 'antd';
import type {InputRef} from 'antd';
import {TARGET_GROUP_DEFAULT_STATE} from "../../../redux/merchant";
import TargetGroupDatePicker from "../target_group/TargetGroupDatePicker";
import OfferRadioSelect from "./OfferRadioSelect";
import {
    getMerchantTargetGroupUsers,
    getTargetGroupUsersExceptMerchant
} from "../../../http/targetGroup";
import {formatNumber} from "../../../services/numbers";
import {showCustomersNumber} from "../../../services/utils";
import TargetGroupRangesSelect from "../target_group/TargetGroupRangesSelect";
import TargetGroupAmountRanges from "../target_group/TargetGroupAmountRanges";
import {isEqual} from "lodash";
import Switch from "../../common/Switch";
import UsersListModal from "../UsersListModal";
import {Tooltip} from "react-tooltip";
import {estimateCost} from "../../../services/offerUtils";
import {getMerchantUsers} from "../../../http/merchant";
import WarningOfferModal from "../WarningOfferModal";


interface StepProps {
    formData: {
        clients: string;
        target_group: TargetGroup;
        offer_localizations: OfferLocation;
        estimated_cost: number;
        number_of_customers: number;
        extra_customers: number,
        offer_user_changes: AdditionalSendCustomer[],
    };
    isEditing: boolean;
    isDuplicate: boolean;
    oldCost: number;
    customersCost: CustomersCost | null;
    currentTargetGroup: string;
    setCurrentTargetGroup: (value: string) => void;
    overrideTargetGroup: boolean;
    setOverrideTargetGroup: (value: boolean) => void;

    handleChangeSubField: (field: string, subfield: string, value: any) => void;
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
}

const sortTargetGroupsByName = (targetGroups: TargetGroup[]) => {
    return [...targetGroups].sort((a, b) => {
        // Convert names to lowercase for case-insensitive sorting
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;

        // If names are equal, sort by start_date as a secondary criterion
        // This ensures consistent ordering for items with the same name
        if (nameA === nameB) {
            const dateA = new Date(a.start_date).getTime();
            const dateB = new Date(b.start_date).getTime();
            return dateA - dateB;
        }

        return 0;
    });
}


const Step1: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        handleChangeSubField,
                                        isEditing,
                                        isDuplicate,
                                        oldCost,
                                        customersCost,
                                        currentTargetGroup,
                                        setCurrentTargetGroup,
                                        overrideTargetGroup,
                                        setOverrideTargetGroup,
                                        nextStep,
                                        prevStep,
                                    }) => {
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const [userListIsOpen, setUserListIsOpen] = useState(false);
    const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false);
    const [myLocation, setMyLocation] = useState<Coordinates | null>(null);
    const [shouldEstimateUsers, setShouldEstimateUsers] = useState(false);

    const estimateTargetGroupUsers = () => {
        setShouldEstimateUsers(true);
    }

    const targetGroups = useSelector(getTargetGroups);
    const sortedTargetGroups = sortTargetGroupsByName(targetGroups);
    const [options, setOptions] = useState<TargetGroup[]>(sortedTargetGroups);
    const offerDashboard = useSelector(getOfferDashboard);
    const [newTargetGroup, setNewTargetGroup] = useState(TARGET_GROUP_DEFAULT_STATE);
    const [temporaryTargetGroup, setTemporaryTargetGroup] = useState<TargetGroup>(formData.target_group);
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const selectRef = useRef<any>(null);
    const inputRef = useRef<InputRef>(null);

    const [exceptedNumberOfCustomers, setExceptedNumberOfCustomers] = useState<number>(0);
    const [reachCount, setReachCount] = useState<number>(offerDashboard.offer_current_range);
    const [previousRange, setPreviousRange] = useState(offerDashboard.offer_current_range);

    const [customerList, setCustomerList] = useState<Customer[] | []>([]);
    const [estimatedCustomerList, setEstimatedCustomerList] = useState<Customer[] | []>([]);

    const isFormFilled = Boolean(formData.target_group.name.trim());

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setMyLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        getLocation();
    }, []);


    useEffect(() => {
        if (offerDashboard.offer_current_range !== previousRange) {
            const difference = previousRange - offerDashboard.offer_current_range;

            setReachCount(prevReachCount => prevReachCount - difference);

            setPreviousRange(offerDashboard.offer_current_range);
        }
    }, [offerDashboard.offer_current_range]);

    useEffect(() => {
        if (isEditing || isDuplicate) {
            setTemporaryTargetGroup(formData.target_group);
            const getOfferCustomersWithChanges = async () => {
                try {

                    const allUsers = await getMerchantUsers('');

                    const urlParam = new URLSearchParams();
                    const targetGroupJson = JSON.stringify(formData.target_group);
                    urlParam.append('target_group_data', targetGroupJson);

                    if (formData.offer_localizations && formData.offer_localizations.offer_range) {
                        urlParam.append('longitude', String(formData.offer_localizations.coordinates.lng));
                        urlParam.append('latitude', String(formData.offer_localizations.coordinates.lat));
                        urlParam.append('range_km', String(formData.offer_localizations.offer_range));
                    }

                    const clients = await getMerchantTargetGroupUsers(urlParam.toString()) as Customer[];

                    const targetGroupCustomers = allUsers.map((user: Customer) => {
                        const matchedUser = clients.find((customer: Customer) => customer.id === user.id);
                        return matchedUser
                            ? {...matchedUser, status: true}
                            : {...user, status: false};
                    });

                    const updatedTargetGroupCustomers = targetGroupCustomers.map((user: Customer) => {
                        const change = formData.offer_user_changes.find((change) => change.id === user.id);
                        return change
                            ? {...user, status: change.status}
                            : user;
                    });
                    setEstimatedCustomerList(targetGroupCustomers);
                    setCustomerList(updatedTargetGroupCustomers);
                    const exceptedCustomersLength: number = targetGroupCustomers.filter((customer: Customer) => customer.status === true).length;
                    setExceptedNumberOfCustomers(exceptedCustomersLength);
                    setReachCount(offerDashboard.offer_current_range);
                } catch (error) {
                    console.error('Błąd podczas pobierania użytkowników:', error);
                    return [];
                }
            };
            getOfferCustomersWithChanges();
        } else {
            setTemporaryTargetGroup(TARGET_GROUP_DEFAULT_STATE);
            setExceptedNumberOfCustomers(formData.number_of_customers);
            setReachCount(formData.number_of_customers);
        }
    }, [isEditing, isDuplicate]);

    useEffect(() => {
        if (shouldEstimateUsers) {
            const getTargetGroupCustomers = async () => {
                try {
                    const allUsers = await getMerchantUsers('');
                    const urlParam = new URLSearchParams();
                    const targetGroupJson = JSON.stringify(formData.target_group);
                    urlParam.append('target_group_data', targetGroupJson);
                    if (formData.offer_localizations && formData.offer_localizations.offer_range) {
                        urlParam.append('longitude', String(formData.offer_localizations.coordinates.lng));
                        urlParam.append('latitude', String(formData.offer_localizations.coordinates.lat));
                        urlParam.append('range_km', String(formData.offer_localizations.offer_range));
                    }
                    if (formData.clients === "current") {
                        const clients = await getMerchantTargetGroupUsers(urlParam.toString()) as Customer[];

                        const targetGroupCustomers = allUsers.map((user: Customer) => {
                            const matchedUser = clients.find((customer: Customer) => customer.id === user.id);
                            return matchedUser
                                ? {...matchedUser, status: true}
                                : {...user, status: false};
                        });
                        setCustomerList(targetGroupCustomers);
                        setEstimatedCustomerList(targetGroupCustomers);
                        setExceptedNumberOfCustomers(targetGroupCustomers.filter((customer: Customer) => customer.status).length);
                        setReachCount(targetGroupCustomers.filter((customer: Customer) => customer.status).length);
                        setShouldEstimateUsers(false);

                    }
                    if (formData.clients === "new") {
                        getTargetGroupUsersExceptMerchant(urlParam.toString()).then((numberOfClients: number) => {
                            setExceptedNumberOfCustomers(numberOfClients);
                            setReachCount(numberOfClients);
                            setShouldEstimateUsers(false);
                        })
                    }
                } catch (e) {
                    console.error('Błąd podczas pobierania użytkowników:', e);
                    return [];
                }
            };
            getTargetGroupCustomers();
        }
    }, [shouldEstimateUsers, formData.target_group, formData.clients, formData.offer_localizations]);


    useEffect(() => {
        if (isEditing || isDuplicate) {
            if (temporaryTargetGroup.id && hasSignificantChanges(temporaryTargetGroup, formData.target_group)) {
                if (currentTargetGroup !== "needToSave") {
                    setCurrentTargetGroup("needToUpdate");
                }
                estimateTargetGroupUsers();
            }
        } else {
            if (hasSignificantChanges(temporaryTargetGroup, formData.target_group)) {
                if (currentTargetGroup !== "needToSave") {
                    setCurrentTargetGroup("needToUpdate");
                }
                estimateTargetGroupUsers();
            }
        }

    }, [formData.target_group, temporaryTargetGroup]);

    useEffect(() => {
        const estimatedActiveUsers = estimatedCustomerList.filter((customer) => customer.status).map((c) => c.id);
        const extraUsers = customerList.filter(
            (customer) => customer.status && !estimatedActiveUsers.includes(customer.id)
        ).length;
        handleChange("extra_customers", extraUsers);
    }, [customerList, estimatedCustomerList]);


    const compareCustomerListsWithEstimated = (
        currentList: Customer[],
        estimatedList: Customer[]
    ): AdditionalSendCustomer[] => {
        const newChanges: AdditionalSendCustomer[] = [];

        currentList.forEach((customer) => {
            const hasDifferentStatus = estimatedList.some(
                (estCustomer) => (estCustomer.id === customer.id && estCustomer.status !== customer.status)
            );
            if (hasDifferentStatus && customer.status) {
                newChanges.push({
                    id: customer.id,
                    status: true,
                });
            }
            if (hasDifferentStatus && !customer.status) {
                newChanges.push({
                    id: customer.id,
                    status: false,
                });
            }
        });

        return newChanges;
    };

    const onSave = () => {
        if (isFormFilled) {
            const newChanges = compareCustomerListsWithEstimated(customerList, estimatedCustomerList);
            if (newChanges.length > 0) {
                handleChange("offer_user_changes", newChanges);
            }
            if (reachCount) {
                handleChange("estimated_cost", estimateCost(formData.clients, reachCount, formData.extra_customers, customersCost))
                handleChange("number_of_customers", reachCount)
            }
            nextStep();
        }
    };

    const addItem = () => {
        if (newTargetGroup.name) {
            const newId = new Date().valueOf();
            const newGroup = {...newTargetGroup, id: newId}
            setOptions([...options, newGroup]);
            handleChange("target_group", newGroup);
            if (selectRef.current) {
                selectRef.current.blur();
            }
            handleSelectNameChange({
                value: String(newId),
                label: newTargetGroup.name
            });
            setCurrentTargetGroup("needToSave");
            setDropdownIsOpen(false);
            setNewTargetGroup(TARGET_GROUP_DEFAULT_STATE);

            estimateTargetGroupUsers();
        }
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTargetGroup({
            ...newTargetGroup,
            name: event.target.value
        });
    };

    const handleSelectNameChange = (selectedOption: { value: string; label: string; }) => {
        const selected = options.find(option => String(option.id) === selectedOption.value);
        if (selected) {
            if (selected.id) {
                setCurrentTargetGroup("needToKeep");
            } else {
                setCurrentTargetGroup("needToSave");
            }
            setTemporaryTargetGroup(selected);
            handleChange("target_group", selected);

            estimateTargetGroupUsers();
        }
    }

    const handleReachCountChange = (newCount: number) => {
        const currentActiveCount = customerList.filter((customer) => customer.status).length;

        let updatedList = [...customerList];

        if (newCount > currentActiveCount) {
            const usersToActivate = newCount - currentActiveCount;
            let activatedCount = 0;

            updatedList = updatedList.map((customer) => {
                if (!customer.status && activatedCount < usersToActivate) {
                    activatedCount++;
                    return {...customer, status: true};
                }
                return customer;
            });
        } else if (newCount < currentActiveCount) {
            const usersToDeactivate = currentActiveCount - newCount;
            let deactivatedCount = 0;

            updatedList = updatedList.map((customer) => {
                if (customer.status && deactivatedCount < usersToDeactivate) {
                    deactivatedCount++;
                    return {...customer, status: false};
                }
                return customer;
            });
        }

        const estimatedActiveUsers = estimatedCustomerList.filter((customer) => customer.status).map((c) => c.id);
        const extraUsers = updatedList.filter(
            (customer) => customer.status && !estimatedActiveUsers.includes(customer.id)
        ).length;

        setCustomerList(updatedList);
        setReachCount(newCount);
        handleChange("extra_customers", extraUsers);
    };

    const handleMapSave = (location: OfferLocation) => {
        const newRange = location.offer_range < 1 ? location.offer_range.toFixed(2) : location.offer_range.toFixed(0);
        const newLocation = {...location, offer_range: Number(newRange)};
        handleChange("offer_localizations", newLocation);
        setMapIsOpen(false);
    };

    const handleMapClose = () => {
        setMapIsOpen(false);
    }

    const resetLocalization = () => {
        handleChange("offer_localizations", DEFAULT_OFFER_LOCATION)
    }

    const hasSignificantChanges = (original: TargetGroup, current: TargetGroup) => {
        return !isEqual(original, current);
    };

    const offerRange = formData.offer_localizations && formData.offer_localizations.offer_range ? formData.offer_localizations.offer_range : 0;

    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <OfferRadioSelect selectedOption={formData.clients}
                                  handleOptionChange={e => handleChange("clients", e.target.value)}/>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Utwórz lub wybierz grupę docelową<span
                            className="required-mark"> *</span></p>
                        <Select<{ value: string; label: string; }>
                            className="input"
                            variant="borderless"
                            placeholder="Grupa docelowa..."
                            open={dropdownIsOpen}
                            onDropdownVisibleChange={setDropdownIsOpen}
                            onChange={handleSelectNameChange}
                            value={formData.target_group.id ? {
                                value: String(formData.target_group.id),
                                label: String(formData.target_group.name)
                            } : undefined}
                            labelInValue
                            optionLabelProp="label"
                            fieldNames={{
                                label: 'label',
                                value: 'value'
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider className="divider"/>
                                    <Space className="space">
                                        <InputAntd
                                            className="space-input"
                                            placeholder="Wprowadź nazwę nowej grupy docelowej"
                                            ref={inputRef}
                                            value={newTargetGroup.name}
                                            onChange={onNameChange}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button onClick={addItem} icon={icons.plusIcon} label={"Dodaj grupę"}/>
                                    </Space>
                                </>
                            )}
                            options={options.map((item: TargetGroup) => ({
                                key: String(item.id),
                                value: String(item.id),
                                label: item.name
                            }))}

                            ref={selectRef}
                        />
                    </div>
                </div>
                <div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='age_ranges' title='Wiek' placeholder="Wybierz wiek..."
                                                     targetGroup={formData.target_group}
                                                     setTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="select-wrapper">
                            <p className="label label-margin">Płeć</p>
                            <Select
                                mode="multiple"
                                value={formData.target_group.gender ? formData.target_group.gender : undefined}
                                onChange={(value) => handleChangeSubField("target_group", "gender", value)}
                                allowClear={true}
                                placeholder="Wybierz płeć..."
                                showSearch={false}
                                options={[
                                    {value: "male" as Gender, label: 'Mężczyzna'},
                                    {value: "female" as Gender, label: 'Kobieta'},
                                    {value: "unknown" as Gender, label: 'Inna'},
                                ]}
                                className="dropdown-select"
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='transactions_ranges'
                                                     title={formData.clients === "current" ? 'Liczba transakcji w sklepie' : 'Liczba transakcji'}
                                                     placeholder="Wybierz liczbę transakcji..."
                                                     targetGroup={formData.target_group}
                                                     setTargetGroup={value => handleChange("target_group", value)}/>
                            <TargetGroupDatePicker editedTargetGroup={formData.target_group}
                                                   setEditedTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupAmountRanges
                                editedTargetGroup={formData.target_group}
                                setEditedTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper">
                        <div className="wrapper">
                            <p className="label mr8">Lokalizacja</p>
                            <div className="icon-wrapper">
                                <Tooltip id="localization" className="tooltip" noArrow={true}>
                                    <p className="tooltip-body">Filtr zasięgu pozwala dotrzeć do klientów,
                                        którzy ostatnio kupowali w danej lokalizacji.</p>
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="localization" data-tooltip-place="top"/>
                            </div>
                        </div>
                        <p className="label required-mark pointer"
                           onClick={resetLocalization}>Wyczyść</p>
                    </div>
                    <div className="localization-input">
                        <img className="pin"
                             src={icons.greyPinIcon} alt={'Pin'}/>
                        <input type="text"
                               className={formData.offer_localizations.place_name === '' ? `localization-input-place` : `localization-input-place-active`}
                               value={formData.offer_localizations.place_name}
                               onClick={() => setMapIsOpen(true)}
                               onChange={() => handleMapSave}
                               placeholder="Wybierz lokalizację..."
                        />
                        <input type="text"
                               className={offerRange ? `input-radius-active` : `input-radius`}
                               placeholder="+ 0 km"
                               value={`+ ${offerRange} km`}
                               readOnly
                               onChange={() => handleMapSave}/>
                    </div>
                    <MapModal
                        isOpen={mapIsOpen}
                        onClose={handleMapClose}
                        userLocation={myLocation}
                        savedLocation={formData.offer_localizations}
                        onSaveRange={handleMapSave}
                    />
                </div>
                {(currentTargetGroup === "needToUpdate" && !formData.target_group.is_offer_duplicate && formData.target_group.name) &&
                    <div className="section">
                        <div className="select-wrapper one-line">
                            <p className="label">Czy chcesz nadpisać grupę?</p>
                            <Switch checked={overrideTargetGroup}
                                    setChecked={(value) => {
                                        setOverrideTargetGroup(value);
                                    }}/>
                        </div>
                    </div>
                }
                <div className="section">
                    <div className="offer-wrapper text-gray">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span className="label w700">{showCustomersNumber(exceptedNumberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper with-border">
                        <div className="wrapper">
                            <span className="label">Liczba osób, do których chcesz dotrzeć:</span>
                            <div className="icon-wrapper">
                                <Tooltip id="customers count" className="tooltip" noArrow={true}>
                                    <p className="tooltip-body">Liczba osób, do których chcesz dotrzeć,
                                        może być większa niż przewidywany zasięg promocji. Klikając ikonę ołówka,
                                        możesz ręcznie dodać lub usunąć konkretne osoby z listy odbiorców.</p>
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="customers count" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <div className="icon-wrapper">
                            {formData.clients === "current" &&
                                <img
                                    className="pen-icon"
                                    src={icons.pencilIcon}
                                    onClick={() => setUserListIsOpen(true)}
                                    alt={"Show users list"}/>
                            }
                            <div className="slider-value-container">
                                <div className="slider-input-wrapper">
                                    <div className="input-wrapper">
                                        <input
                                            value={reachCount}
                                            onChange={e => handleReachCountChange((e.target.valueAsNumber))}
                                            className="form-control"
                                            type="number"
                                            min={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offer-wrapper ">
                        <div className="wrapper">
                            <span className="label">Łączny koszt promocji: </span>
                            <div className="icon-wrapper">
                                <Tooltip id="customers prize" className="tooltip" noArrow={true}>
                                    {customersCost ?
                                        <>
                                            <p className="tooltip-body">Cena za obecnego
                                                klienta: {formatNumber(customersCost.current_customer)}</p><p
                                            className="tooltip-body">Cena za ręcznie dodanego obecnego
                                            klienta: {formatNumber(customersCost.added_customer)}</p><p
                                            className="tooltip-body">Cena za nowego
                                            klienta: {formatNumber(customersCost.new_customer)}</p>
                                        </>
                                        :
                                        <p className="tooltip-body">Pobieranie aktualnych danych...</p>
                                    }
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="customers prize" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <span
                            className="label w700">{formatNumber(estimateCost(formData.clients, reachCount, formData.extra_customers, customersCost))}</span>
                    </div>
                    {isEditing && offerDashboard.offer_current_cost < estimateCost(formData.clients, reachCount, formData.extra_customers, customersCost) &&
                        <div className="offer-wrapper blue">
                            <span className="label">Do dopłaty: </span>
                            <span
                                className="label w700">{formatNumber(estimateCost(formData.clients, reachCount, formData.extra_customers, customersCost) - offerDashboard.offer_current_cost)}</span>
                        </div>
                    }

                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                    <UsersListModal
                        isOpen={userListIsOpen}
                        estimateUsers={customerList}
                        setEstimateUsers={setCustomerList}
                        numberOfCustomers={reachCount}
                        setNumberOfCustomers={setReachCount}
                        onClose={() => setUserListIsOpen(false)}
                    />
                </div>
                <div className="modal-footer">
                    <Button label="Anuluj"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label={"Dalej"}
                            dataTooltipId="UnFilledName"
                            disabled={isSubmitted && !isFormFilled}
                            onClick={() => {
                                setIsSubmitted(true);
                                if (isEditing && (estimateCost(formData.clients, reachCount, formData.extra_customers, customersCost) < oldCost)) {
                                    setWarningModalIsOpen(true);
                                    return;
                                }
                                onSave()
                            }}
                    />
                    {(!isFormFilled) &&
                        <Tooltip id="UnFilledName" className="tooltip" noArrow={true}>
                            <p className="tooltip-title">Aby przejść dalej wypełnij poniższe pola</p>
                            <p className="tooltip-body">grupa docelowa</p>
                        </Tooltip>
                    }
                </div>
                <WarningOfferModal
                    isOpen={warningModalIsOpen}
                    nextStep={() => {
                        onSave()
                        setWarningModalIsOpen(false)
                    }}
                    onClose={() => setWarningModalIsOpen(false)}/>
            </div>
        </div>
    );
};

export default Step1;