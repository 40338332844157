import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import { Input } from 'antd';
import {Customer} from "../../services/interfaces";
import {icons} from "../../services/images";
import {getMerchantUsers} from "../../http/merchant";
import Checkbox from "../common/Checkbox";
import Button from "../common/Button";


interface UsersListModalProps {
    isOpen: boolean,
    onClose: () => void;
    estimateUsers: Customer[],
    setEstimateUsers: React.Dispatch<React.SetStateAction<Customer[]>>;
    numberOfCustomers: number;
    setNumberOfCustomers: React.Dispatch<React.SetStateAction<number>>;
}

const UsersListModal = ({
                            isOpen,
                            onClose,
                            estimateUsers,
                            setEstimateUsers,
                            setNumberOfCustomers
                        }: UsersListModalProps) => {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [users, setUsers] = useState<Customer[]>([]);


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userData = await getMerchantUsers('');
                const updatedUsers = userData.map((user: Customer) => {
                    const estimatedUser = estimateUsers.find((u :Customer) => u.id === user.id);
                    return {
                        ...user,
                        status: estimatedUser ? estimatedUser.status : undefined
                    };
            });
                setUsers(updatedUsers);
            } catch (error) {
                console.error('Błąd podczas pobierania użytkowników:', error);
            }
        };

        fetchUsers();
    }, [estimateUsers]);

    const areAllSelected = users.every(user => user.status);

    const toggleAll = () => {

        const updatedUsers = users.map(user => ({
            ...user,
            status: !areAllSelected
        }));

        setUsers(updatedUsers);

        setEstimateUsers(updatedUsers);

        const selectedCount = !areAllSelected ? updatedUsers.length : 0;
        setNumberOfCustomers(selectedCount);
    };

    const handleSearch = async () => {
        try {
            const urlParam = new URLSearchParams();
            urlParam.append('q', searchTerm);
            const userData = await getMerchantUsers(urlParam.toString());
            const updatedUsers = userData.map((user: Customer) => {
                const estimatedUser = estimateUsers.find((u: Customer) => u.id === user.id);
                return {
                    ...user,
                    status: estimatedUser ? estimatedUser.status : undefined
                };
            });
            setUsers(updatedUsers);
        } catch (error){
            console.error('Błąd podczas wyszukiwania użytkowników:',error);
        }
    };

    const handleCheckboxChange = (user: Customer) => {
        const updatedUsers = users.map(u =>
            u.id === user.id ? { ...u, status: !u.status } : u
        );

        setUsers(updatedUsers);

        setEstimateUsers(prevUsers => {
            // Usuń użytkownika jeśli już istnieje
            const filteredPrevUsers = prevUsers.filter(u => u.id !== user.id);

            // Dodaj użytkownika tylko jeśli status nie jest undefined
            const userStatus = updatedUsers.find(u => u.id === user.id)?.status;
            if (userStatus !== undefined) {
                return [...filteredPrevUsers, { ...user, status: userStatus }];
            }

            return filteredPrevUsers;
        });

         // Aktualizuj liczbę użytkowników, zachowując poprzednią wartość
        setNumberOfCustomers(prevNumber => {

            const userStatus = updatedUsers.find(u => u.id === user.id)?.status;
            return userStatus === true
                ? prevNumber + 1
                : prevNumber - 1;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="users-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title">Dodaj lub usuń użytkowników [{users.filter((users)=>users.status).length}/{users.length}]</h5>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="search-wrapper">
                        <div className="input">
                            <img className="icon"
                                 src={icons.search} alt={'Pin'}/>
                            <Input type="text"
                                   className="input-text"
                                   allowClear
                                   variant={"borderless"}
                                   value={searchTerm || ''}
                                   onChange={e => setSearchTerm(e.target.value)}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter') {
                                           handleSearch();
                                       }
                                   }}
                                   placeholder="Szukaj"
                                   maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="checkbox-wrapper-all">
                        <div className="checkbox-all" onClick={toggleAll}>
                            <input
                                type="checkbox"
                                checked={areAllSelected}
                            />
                            <label>
                                Zaznacz wszystkich
                            </label>
                        </div>
                    </div>
                    <div className="users-wrapper">
                        {users.map((user, index) => (
                            <div key={index} className="checkbox-wrapper">
                                <Checkbox user={user} onChange={() => handleCheckboxChange(user)}/>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default UsersListModal;