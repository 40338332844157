import Modal from "react-modal";
import {cloudFrontUrl, icons, images} from "../../services/images";
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import Button from "../common/Button";
import ColorPicker from "../merchant_point/ColorPicker";
import Switch from "../common/Switch";
import {isBase64Image, isLogo} from "../../services/imageUtils";
import {useSelector} from "react-redux";
import {getCurrentMerchantPoint, getCurrentOffer} from "../../redux/selectors/merchantSelector";
import AddImage from "../merchant_point/AddImage";
import {HexAlphaColorPicker} from "react-colorful";
import domtoimage from 'dom-to-image';
import InputDescription from "../common/InputDescription";

interface ImgGeneratorModalProps {
    isOpen: boolean;
    onClose: ()=>void;
}

const saveAsJpeg = async (elementToCapture: HTMLElement): Promise<void> => {
  try {
    const dataUrl = await domtoimage.toJpeg(elementToCapture, {quality: 1, cacheBust: true});

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'obrazek-promocyjny.jpg';
    link.click();
  } catch (error) {
    console.error('Błąd podczas zapisywania obrazu:', error);
  }
};

const ImgGeneratorModal = ({isOpen, onClose}: ImgGeneratorModalProps) => {
    const [predefinedImageColor, setPredefinedImageColor] = useState<number | null>(null);
    const [imageCustomColor, setImageCustomColor] = useState<string>("");
    const [textCustomColor, setTextCustomColor] = useState<string>("");
    const [showImageColorPicker, setShowImageColorPicker] = useState<boolean>(false);
    const [showTextColorPicker, setShowTextColorPicker] = useState<boolean>(false);
    const [textColor, setTextColor] = useState<number>(1);
    const [themeColor, setThemeColor] = useState<number>(1);
    const [descShow, setDescShow] = useState<boolean>(false);
    const [textSize, setTextSize] = useState<string>("big");
    const [textFormat, setTextFormat] = useState<string>("left");
    const currentMerchantPoint = useSelector(getCurrentMerchantPoint);
    const currentOffer = useSelector(getCurrentOffer);
    const componentRef = useRef<HTMLDivElement>(null);
    const [image, setImage] = useState<string>(currentOffer.image_path);
    const [title, setTitle] = useState<string>(currentOffer.name);
    const [desc, setDesc] = useState<string>(currentOffer.description);

    useEffect(() => {
        setTitle(currentOffer.name);
        setDesc(currentOffer.description);
        setImage(currentOffer.image_path);
    }, [currentOffer]);

    const handleSaveClick = (): void => {
        if (componentRef.current) {
            saveAsJpeg(componentRef.current);
        }
    };

    const themeColorHandler = (colorNumber: number, position: string) => {
        if (position === "top") {
            if (colorNumber === 1) {
                return images.topBlackDecoration;
            }
            if (colorNumber === 2) {
                return images.topBlueDecoration;
            }
        }
        if (position === "bottom") {
            if (colorNumber === 1) {
                return images.bottomBlackDecoration;
            }
            if (colorNumber === 2) {
                return images.bottomBlueDecoration;
            }
        }
        return "coś poszło nie tak";
    };

    const textColorHandler = (colorNumber: number, customColor: string) => {
        if (customColor === "") {
            if (colorNumber === 1) {
                return "black";
            }
            if (colorNumber === 2) {
                return "white";
            }
        } else {
            return;
        }
    };

    const backgroundHandler = (predefinedImageColor: number | null, image: string, imageCustomColor: string): CSSProperties => {
        if (!predefinedImageColor && image === "" && imageCustomColor === "") {
            return {background: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 45.5%, rgba(0, 0, 0, 0) 100%), no-repeat center / cover url(${images.offerImagePlaceHolder}`};
        }
        if (!predefinedImageColor && image !== "" && imageCustomColor === "") {
            return {background: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 45.5%, rgba(0, 0, 0, 0) 100%), no-repeat center / cover url(${isBase64Image(image) ? image : cloudFrontUrl + image}`};
        }
        if (!predefinedImageColor && image === "" && imageCustomColor !== "") {
            return {background: `${imageCustomColor} linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%)`};
        }
        return {};
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="stepper-modal-content filters-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="header-content">
                        <h5 className="mb8">Udostępnij post promocyjny</h5>
                        <p className="label required-mark">W tym miejscu możesz zaprojektować swój obrazek
                            promocyjny.</p>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="img-wrapper">
                        <div className="img" ref={componentRef}>
                            <img className="top-decoration" src={themeColorHandler(themeColor, "top")}
                                 alt={"górna dekoracja"}/>
                            <div className={`content-wrapper ${textSize}-content ${textFormat}`}>
                                <div className="logo" style={{
                                    backgroundImage: `url(${isLogo(currentMerchantPoint.logo_image, currentMerchantPoint.service_types)})`,
                                }}/>
                                <div className={`offer-title-wrapper ${textSize}-title-wrapper`}>
                                    <p className={`offer-title ${textSize}-title ${textColorHandler(textColor, textCustomColor)}`}
                                       style={{color: `${textCustomColor !== "" ? textCustomColor : ""}`}}
                                    >{title}</p>
                                </div>
                                {descShow &&
                                    <div className={`offer-desc-wrapper ${textSize}-desc-wrapper`}>
                                        <p className={`offer-desc ${textSize}-desc ${textColorHandler(textColor, textCustomColor)}`}
                                           style={{color: `${textCustomColor !== "" ? textCustomColor : ""}`}}
                                        >{desc}</p>
                                    </div>
                                }
                            </div>
                            <div
                                className={`img-preview color-${predefinedImageColor && image === "" && imageCustomColor === "" ? predefinedImageColor : ""}`}
                                style={backgroundHandler(predefinedImageColor, image, imageCustomColor)}
                            />
                            <img className="bottom-decoration"
                                 src={themeColorHandler(themeColor, "bottom")}
                                 alt={"dolna dekoracja"}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="label-space-wrapper label-margin">
                            <p className="label">Tło:</p>
                        </div>
                        <div className="theme-wrapper">
                            <div className="add-icon-wrapper">
                                <AddImage
                                    label=""
                                    width={494}
                                    height={494}
                                    type="offer-image"
                                    withIcon
                                    image={image}
                                    setImage={(image) => {
                                        setPredefinedImageColor(null);
                                        setImageCustomColor("");
                                        setImage(image);
                                    }}
                                />
                            </div>
                            <div className="color-wrapper">
                                <ColorPicker
                                    colorRange={10}
                                    color={predefinedImageColor}
                                    setColor={(color) => {
                                        setImage("");
                                        setImageCustomColor("");
                                        setPredefinedImageColor(color);
                                    }}
                                />
                            </div>
                            <img className="add-color-icon" src={icons.colorPickerIcon} alt={"dodaj kolor tła"}
                                 onClick={() => setShowImageColorPicker(!showImageColorPicker)}/>
                            {showImageColorPicker &&
                                <Modal
                                    onRequestClose={() => setShowImageColorPicker(!showImageColorPicker)}
                                    isOpen={showImageColorPicker}
                                    overlayClassName="modal-wrapper"
                                    ariaHideApp={false}
                                    className={`modal-content image-modal color`}
                                >
                                    <div className="modal-header">
                                        <h5>Przytnij zdjecie</h5>
                                        <div className="modal-close"
                                             onClick={() => setShowImageColorPicker(!showImageColorPicker)}>
                                            <img src={icons.closeIcon} alt=""/>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <HexAlphaColorPicker color={imageCustomColor} onChange={setImageCustomColor}/>
                                    </div>
                                    <div className="modal-footer">
                                        <Button
                                            label="Anuluj"
                                            type="Default"
                                            onClick={() => setImageCustomColor("")}
                                        />
                                        <Button
                                            onClick={() => {
                                                setImage("");
                                                setPredefinedImageColor(null);
                                                setShowImageColorPicker(!showImageColorPicker);
                                            }}
                                            label="Dodaj kolor"
                                        />
                                    </div>
                                </Modal>
                            }
                        </div>
                        <div className="mb16 theme-color-picker">
                            <div className="label-space-wrapper label-margin">
                                <p className="label">Motyw:</p>
                            </div>
                            <ColorPicker
                                colorRange={2}
                                color={themeColor}
                                setColor={(color) => setThemeColor(color)}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="label-space-wrapper mb16">
                            <p className="label w700">Tekst</p>
                        </div>
                        <div className="select-wrapper">
                            <p className="label mb8">Nazwa promocji:<span
                                className="required-mark"> *</span></p>
                            <div className="input mb16">
                                <input type="text"
                                       className="input-text"
                                       value={title}
                                       onChange={(e) => setTitle(e.target.value)}
                                       minLength={3}
                                       maxLength={50}
                                />
                            </div>
                            <div className="mb8 desc-switch-wrapper">
                                <p className="label mr8">Opis</p>
                                <Switch checked={descShow}
                                        setChecked={(value) => {
                                            setDescShow(value)
                                        }}
                                />
                            </div>

                            <div className="input">
                                <InputDescription
                                    className={`input-text ${!descShow && 'text-gray'}`}
                                    value={desc}
                                    onChange={(e) => setDesc(e)}
                                    isDisabled={!descShow}
                                    maxLength={150}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-theme-wrapper mb16">
                            <div className="label-space-wrapper label-margin">
                                <p className="label">Kolor tekstu</p>
                                <div className="text-color-wrapper text-color-picker">
                                                <ColorPicker
                                                    colorRange={2}
                                                    color={textColor}
                                                    setColor={(color) => {
                                                        setTextCustomColor("");
                                                        setTextColor(color);
                                                    }}
                                                />
                                                <img className="color-picker-icon" src={icons.colorPickerIcon}
                                                     alt={"dodaj kolor tekstu"}
                                                     onClick={() => setShowTextColorPicker(!showTextColorPicker)}/>
                                                {showTextColorPicker &&
                                                    <Modal
                                                        onRequestClose={() => setShowTextColorPicker(!showTextColorPicker)}
                                                        isOpen={showTextColorPicker}
                                                        overlayClassName="modal-wrapper"
                                                        ariaHideApp={false}
                                                        className={`modal-content image-modal color`}
                                                    >
                                                        <div className="modal-header">
                                                            <h5>Przytnij zdjecie</h5>
                                                            <div className="modal-close"
                                                                 onClick={() => setShowTextColorPicker(!showTextColorPicker)}>
                                                                <img src={icons.closeIcon} alt=""/>
                                                            </div>
                                                        </div>
                                                        <div className="modal-body">
                                                            <HexAlphaColorPicker color={textCustomColor}
                                                                                 onChange={setTextCustomColor}/>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <Button
                                                                label="Anuluj"
                                                                type="Default"
                                                                onClick={() => setTextCustomColor("")}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    setTextColor(0);
                                                                    setShowTextColorPicker(!showTextColorPicker);
                                                                }}
                                                                label="Dodaj kolor"
                                                            />
                                                        </div>
                                                    </Modal>
                                                }
                                            </div>
                                        </div>
                                        <div className="label-space-wrapper">
                                            <p className="label mb8">Rozmiar tekstu</p>
                                            <div className="text-size-wrapper">
                                                <img className="big-icon"
                                                     src={textSize === "big" ? icons.textSizeIcon : icons.textSizeIconInactive}
                                                     alt={"rozmiar tekstu"} onClick={() => setTextSize("big")}/>
                                                <img className="medium-icon"
                                                     src={textSize === "medium" ? icons.textSizeIcon : icons.textSizeIconInactive}
                                                     alt={"rozmiar tekstu"} onClick={() => setTextSize("medium")}/>
                                                <img className="small-icon"
                                                     src={textSize === "small" ? icons.textSizeIcon : icons.textSizeIconInactive}
                                                     alt={"rozmiar tekstu"} onClick={() => setTextSize("small")}/>
                                            </div>
                                        </div>
                                        <div className="label-space-wrapper">
                                            <p className="label mb8">Wyrównanie tekstu</p>
                                            <div className="text-format-wrapper">
                                                <img className="icon"
                                                     src={textFormat === "left" ? icons.textAlignLeftIcon : icons.textAlignLeftIconInactive}
                                                     alt={"Formatowanie tekstu"} onClick={() => setTextFormat("left")}/>
                                                <img className="icon"
                                                     src={textFormat === "center" ? icons.textCenteredIcon : icons.textCenteredIconInactive}
                                                     alt={"Formatowanie tekstu"}
                                                     onClick={() => setTextFormat("center")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="img-gen-footer jc-center">
                                <Button label="Zapisz"
                                        onClick={handleSaveClick}
                                        fullWidth
                                />
                            </div>
                        </div>
        </Modal>
)
};

export default ImgGeneratorModal;