import React from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import Button from "../common/Button";

interface WarningOfferModalProps {
    isOpen: boolean,
    nextStep: () => void;
    onClose: () => void;
}

const WarningOfferModal = ({
                            isOpen,
                            onClose,
                            nextStep,
                        }: WarningOfferModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="end-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <img className="icon" src={icons.redWarningIcon} alt="warning"/>
                    <div className="title-wrapper">
                        <h2 className="modal-title">Jesteś pewien?</h2>
                    </div>
                </div>
                <div className="modal-body">
                    <p>Łączny koszt promocji jest niższy jego wcześniejsza kwota</p>
                    <div className="button-wrapper">
                        <Button label="Anuluj"
                                onClick={onClose}
                                type="Default"
                        />
                        <Button label="Tak, dalej"
                                onClick={nextStep}
                                type="Red"
                                extraStyle={{
                                    lineHeight: 1
                                }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WarningOfferModal;