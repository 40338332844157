import React, {useState} from 'react';
import Button from "../../common/Button";
import {Divider, Select, SelectProps, Slider, Space, Tag} from "antd";
import FiltersDatePicker from "../filters/FiltersDatePicker";
import Switch from "../../common/Switch";
import {
    CashbackMerchantLevel, CustomersCost,
    MerchantPointSimple,
    OfferDays,
    OfferType,
    OfferUsage
} from "../../../services/interfaces";
import {getAddressWithoutCountry, showCustomersNumber} from "../../../services/utils";
import {getMerchantPoints} from "../../../redux/selectors/merchantSelector";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, formatNumber} from "../../../services/numbers";
import InputDescription from "../../common/InputDescription";
import {cashbackMerchantDictionary} from "../../../services/dictionaries/cashbackDict";
import {Tooltip} from "react-tooltip";
import {icons} from "../../../services/images";

interface StepProps {
    formData: {
        name: string;
        type: OfferType | undefined;
        extra_cashback: number;
        offer_merchant_points: number[];
        start_date: Date | null;
        end_date: Date | null;
        offer_days: { value: string, label: string }[];
        usage: OfferUsage;
        description: string;
        direct_addressing: boolean;
        direct_addressing_style: string,
        send_push_notification: boolean;
    };
    isEditing: boolean;
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
    oldCost: number;
    customersCost: CustomersCost|null;
}

const formFieldsDict: { [key: string]: string } = {
    name: 'Nazwa promocji',
    type: 'Typ promocji',
    merchant_points: 'Punkty biorące udział w promocji',
    date: 'Czas trwania',
};

type TagRender = SelectProps['tagRender'];

const Option = Select.Option;

export const tagRender: TagRender = (props) => {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={"#0071FC"}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className="tag"
        >
            {label}
        </Tag>
    );
};

const Step2: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        nextStep,
                                        prevStep,
                                        numberOfCustomers,
                                        estimateCost,
                                        isEditing,
                                        oldCost,
                                        customersCost
                                    }) => {
    const merchantPoints = useSelector(getMerchantPoints);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const getUnfilledFields = () => {
        const pointRequiredFields = [
            'name',
            'type',
            'date',
            'merchant_points',

        ];

        const unfilledFields = pointRequiredFields.filter(field => {
            if (field === 'name') {
                return formData.name.trim() === "";
            }
            if (field === 'type') {
                return !formData.type;
            }
            if (field === 'merchant_points') {
                return formData.offer_merchant_points.length === 0
            }
            if (field === 'date') {
                return !formData.start_date || !formData.end_date
            }
        });

        return unfilledFields;
    };
    const isFormFilled = isEmpty(getUnfilledFields());

    const cashbackMerchant = formData.extra_cashback;
    const isPillSelected = (level: CashbackMerchantLevel) =>
        level.min <= cashbackMerchant &&
        level.max >= cashbackMerchant;


    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label label-margin">Nazwa promocji<span className="required-mark"> *</span></p>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={formData.name}
                               onChange={e => handleChange("name", e.target.value)}
                               placeholder="Nadaj nazwę swojej promocji..."
                               maxLength={50}
                        />
                    </div>
                </div>

                <div className="section">
                    <p className="label label-margin">Opis promocji</p>
                    <div className="input-description">
                        <InputDescription
                            className="input-text"
                            value={formData.description}
                            onChange={(e) => handleChange("description", e)}
                            maxLength={150}
                            placeholder="Opis..."
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Rodzaj promocji<span className="required-mark"> *</span></p>
                        <Select
                            value={formData.type ? formData.type : undefined}
                            onChange={(value) => handleChange("type", value)}
                            allowClear={true}
                            placeholder="Wybierz rodzaj promocji..."
                            showSearch={false}
                            options={[
                                {value: "gratis" as OfferType, label: 'Gratis'},
                                {value: "contest" as OfferType, label: 'Konkurs'},
                                {value: "discount" as OfferType, label: 'Rabat'},
                                {value: "event" as OfferType, label: 'Wydarzenie'},
                                {value: "news" as OfferType, label: 'Nowości'},
                                {value: "extra_cashback" as OfferType, label: 'Dodatkowy Cashback'},
                            ]}
                            className="dropdown-select"
                        />
                    </div>
                </div>
                {formData.type === "extra_cashback" &&
                    <div className="section">
                        <p className="label label-margin">Wysokość dodatkowego Cashbacku<span
                            className="required-mark"> *</span></p>
                        <div className="slider-value-container">
                    <span
                        className="label">{formatNumber(cashbackMerchantDictionary[0].min, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                            <div className="slider-input-wrapper">
                                <div className="input-wrapper">
                                    <span className="label">+</span>
                                    <input
                                        value={formData.extra_cashback}
                                        onChange={(value) => handleChange("extra_cashback", value)}
                                        min={cashbackMerchantDictionary[0].min}
                                        max={cashbackMerchantDictionary[2].max}
                                        className="form-control"
                                        type="number"
                                    />
                                </div>
                                <span className="label">%</span>
                            </div>
                            <span
                                className="label">{formatNumber(cashbackMerchantDictionary[2].max, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                        </div>
                        <Slider
                            min={cashbackMerchantDictionary[0].min}
                            max={cashbackMerchantDictionary[2].max}
                            onChange={(value) => handleChange("extra_cashback", value)}
                            value={formData.extra_cashback}
                            step={0.01}
                            tooltip={{formatter: (value) => formatNumber(value, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}}
                        />
                        <div className="cashback-size-select">
                            {cashbackMerchantDictionary.map((size, index) =>
                                <div
                                    key={`CashbackPill${index}`}
                                    className={`cashback-size-pill ${size.name.toLowerCase()} ${isPillSelected(size) ? 'selected' : ''}`}
                                >
                                    <img src={size.iconUri} alt=""/>
                                    <span>{size.label}</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Wybierz punkty biorące udział w promocji
                            <span className="required-mark"> *</span>
                        </p>
                        <Select
                            value={formData.offer_merchant_points}
                            onChange={(value) => {
                                handleChange("offer_merchant_points", value)
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Wybierz punkty..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider className="divider" />
                                    <Space className="space">
                                       <Button
                                            onClick={() => {
                                                if (formData.offer_merchant_points.length === merchantPoints.length) {
                                                    handleChange("offer_merchant_points", []);
                                                } else {
                                                    handleChange("offer_merchant_points",
                                                        merchantPoints.map(point => point.id)
                                                    );
                                                }
                                            }}
                                            label={formData.offer_merchant_points.length === merchantPoints.length
                                                ? 'Odznacz wszystkie'
                                                : 'Zaznacz wszystkie'
                                            }
                                       />
                                    </Space>
                                </>
                            )}
                        >
                            {merchantPoints.map((merchantPoint: MerchantPointSimple) => (
                                <Option
                                    key={merchantPoint.id}
                                    value={merchantPoint.id}
                                >
                                    {getAddressWithoutCountry(merchantPoint)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Czas trwania
                            <span className="required-mark"> *</span>
                        </p>
                        <FiltersDatePicker
                            minTodayDate={true}
                            maxTodayDate={false}
                            title={null}
                            withIcon
                            fromDate={formData.start_date}
                            setFromDate={(value) => handleChange("start_date", value)}
                            toDate={formData.end_date}
                            setToDate={(value) => {
                                handleChange("end_date", value)
                                if (isEmpty(formData.offer_days)) {
                                    handleChange("offer_days", [
                                        {value: "MONDAY" as OfferDays, label: 'W poniedziałek'},
                                        {value: "TUESDAY" as OfferDays, label: 'We wtorek'},
                                        {value: "WEDNESDAY" as OfferDays, label: 'W środę'},
                                        {value: "THURSDAY" as OfferDays, label: 'W czwartek'},
                                        {value: "FRIDAY" as OfferDays, label: 'W piątek'},
                                        {value: "SATURDAY" as OfferDays, label: 'W sobotę'},
                                        {value: "SUNDAY" as OfferDays, label: 'W niedzielę'}
                                    ])
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Dni obowiązywania promocji</p>
                        <Select
                            value={formData.offer_days}
                            onChange={(value) => {
                                handleChange("offer_days", value)
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Ustal w jakie dni ma obowiązywać promocja..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                            options={[
                                {value: "MONDAY" as OfferDays, label: 'W poniedziałek'},
                                {value: "TUESDAY" as OfferDays, label: 'We wtorek'},
                                {value: "WEDNESDAY" as OfferDays, label: 'W środę'},
                                {value: "THURSDAY" as OfferDays, label: 'W czwartek'},
                                {value: "FRIDAY" as OfferDays, label: 'W piątek'},
                                {value: "SATURDAY" as OfferDays, label: 'W sobotę'},
                                {value: "SUNDAY" as OfferDays, label: 'W niedzielę'}
                            ]}
                        />
                    </div>
                </div>
                <div className="section">
                    <p className="label label-margin">Liczba dostępnych użyć</p>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="usage"
                                checked={formData.usage === "one_time"}
                                value="one_time"
                                onChange={e => handleChange("usage", e.target.value)}
                            />
                            <p className="label">jednorazowa</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="usage"
                                checked={formData.usage === "unlimited"}
                                value="unlimited"
                                onChange={e => handleChange("usage", e.target.value)}
                            />
                            <p className="label">nielimitowana</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="one-line select-wrapper">
                        <p className="label">Użyj bezpośrednich zwrotów do klienta</p>
                        <Switch checked={formData.direct_addressing}
                                setChecked={(value) => {
                                    handleChange("direct_addressing", value);
                                }}/>
                    </div>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Hej Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Hej Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            />
                            <p className="label">"Hej Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Cześć Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Cześć Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            />
                            <p className="label">"Cześć Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            /><p className="label">"Adam,..."</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper one-line">
                        <p className="label">Wyślij powiadomienia push do użytkowników</p>
                        <Switch checked={formData.send_push_notification}
                                setChecked={(value) => {
                                    handleChange("send_push_notification", value);
                                }}/>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label w700">{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <div className="wrapper">
                            <span className="label">Łączny koszt promocji: </span>
                            <div className="icon-wrapper">
                                <Tooltip id="customers prize" className="tooltip" noArrow={true}>
                                        {customersCost ?
                                            <>
                                                <p className="tooltip-body">Cena za obecnego
                                                klienta: {formatNumber(customersCost.current_customer)}</p><p
                                                className="tooltip-body">Cena za ręcznie dodanego obecnego
                                                klienta: {formatNumber(customersCost.added_customer)}</p><p
                                                className="tooltip-body">Cena za nowego
                                                klienta: {formatNumber(customersCost.new_customer)}</p>
                                            </>
                                        :
                                            <p className="tooltip-body">Pobieranie aktualnych danych...</p>
                                        }
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="customers prize" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <span
                            className="label w700">{formatNumber(estimateCost)}</span>
                    </div>
                    {isEditing  && oldCost < estimateCost &&
                        <div className="offer-wrapper blue">
                            <span className="label">Do dopłaty: </span>
                            <span
                                className="label w700">{formatNumber(estimateCost - oldCost)}</span>
                        </div>
                    }
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            disabled={isSubmitted && !isFormFilled}
                            dataTooltipId="UnFilledStep2"
                            onClick={() => {
                                setIsSubmitted(true);
                                if (isFormFilled) {
                                    nextStep();
                                }
                            }}
                    />
                    {(!isFormFilled) &&
                            <Tooltip id="UnFilledStep2" className="tooltip" noArrow={true}>
                                <p className="tooltip-title">Aby zapisać wypełnij poniższe pola</p>
                                {getUnfilledFields().map(field =>
                                    <p className="tooltip-body">{formFieldsDict[field]}</p>
                                )}
                            </Tooltip>
                        }
                </div>
            </div>
        </div>
    );
};

export default Step2;