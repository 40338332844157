import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentOffer,
    getOffers
} from "../../redux/selectors/merchantSelector";
import React, {useRef, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import dayjs from "dayjs";
import ActionModal from "./ActionModal";
import {OfferData} from "../../services/interfaces";
import EndOfferModal from "./EndOfferModal";
import ExtendOfferModal from "./ExtendOfferModal";
import {DEFAULT_OFFER_DATA} from "../../redux/merchant";
import PayOfferModal from "./PayOfferModal";
import {getModalOpen} from "../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../redux/navigation";
import {isEmpty} from "lodash";
import EditOfferModal from "./EditOfferModal";
import DuplicateOfferModal from "./DuplicateOfferModal";

const getHowDaysLeft = (end: Date) => {
        const endDate = new Date(end);
        const actualDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        actualDate.setHours(0, 0, 0, 0);

        const milisecondsEndActualDifference = endDate.getTime() - actualDate.getTime();
        const dayDifference = Math.round(milisecondsEndActualDifference / (1000 * 60 * 60 * 24))

        if (dayDifference === 0){
            return 'do Dziś';
        }
        if (dayDifference === 1){
            return 'do Jutra';
        }
        return `${dayDifference} dni`;
    }


const OfferManagerModal = () => {

    const isOpen = useSelector(getModalOpen('offers'));
    const offers = useSelector(getOffers);
    const currentOffer = useSelector(getCurrentOffer);
    const [activeTag, setActiveTag] = useState<string>('all');
    const [activeOffer, setActiveOffer] = useState<OfferData>(currentOffer);
    const [actionActiveModalPosition, setActionActiveModalPosition] = useState({x: 0, y: 0});
    const stoppedItemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const activeItemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const incomingItemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const endedItemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [isActionActiveModalOpen, setIsActionActiveModalOpen] = useState(false);
    const [isActionIncomingModalOpen, setIsActionIncomingModalOpen] = useState(false);
    const [isActionEndedModalOpen, setIsActionEndedModalOpen] = useState(false);
    const [isActionStoppedModalOpen, setIsActionStoppedModalOpen] = useState(false);

    const [endModalIsOpen, setEndModalIsOpen] = useState<boolean>(false);
    const [extendModalIsOpen, setExtendModalIsOpen] = useState<boolean>(false);
    const [payOfferModalIsOpen, setPayOfferModalIsOpen] = useState<boolean>(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const closeOfferManagerModal = () => {
        dispatch(modalCloseAction('offers'));
    }

    const today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());

    const descendingSortByStartDate = (a: OfferData, b: OfferData): number => {
            if (!a.start_date) return 1;
            if (!b.start_date) return -1;
        return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
    };

    const ascendingSortByStartDate = (a: OfferData, b: OfferData): number => {
            if (!a.start_date) return 1;
            if (!b.start_date) return -1;
        return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
    };

    const descendingSortByEndDate = (a: OfferData, b: OfferData): number => {
            if (!a.end_date) return 1;
            if (!b.end_date) return -1;
        return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
    };

    const ascendingSortByEndDate = (a: OfferData, b: OfferData): number => {
            if (!a.end_date) return 1;
            if (!b.end_date) return -1;
        return new Date(a.end_date).getTime() - new Date(b.end_date).getTime();
    };

    const active = offers.filter(offer=> (!offer.is_over && !offer.empty_deposit &&
        offer.start_date && offer.end_date && new Date(offer.start_date) < today && new Date(offer.end_date) > today))
        .sort(ascendingSortByEndDate);
    const incoming = offers.filter(offer => (!offer.is_over && offer.start_date && new Date(offer.start_date) > today))
        .sort(ascendingSortByStartDate);
    const ended = offers.filter(offer=> offer.is_over).sort(descendingSortByEndDate);
    const stopped = offers.filter(offer=> (offer.empty_deposit && !offer.is_over))
        .sort(ascendingSortByEndDate);


    const toggleActiveActionModal = (offer: OfferData, index: number) => {
        const activeItem = activeItemRefs.current[index];
        if (activeItem) {
            const {right, bottom} = activeItem.getBoundingClientRect();
            setActionActiveModalPosition({
                x: right,
                y: bottom,
            });
            setActiveOffer(offer);
        }
        setIsActionActiveModalOpen(!isActionActiveModalOpen);
    };
    const toggleIncomingActionModal = (offer: OfferData, index: number) => {
        const incomingItem = incomingItemRefs.current[index];
        if (incomingItem) {
            const {right, bottom} = incomingItem.getBoundingClientRect();
            setActionActiveModalPosition({
                x: right,
                y: bottom,
            });
            setActiveOffer(offer);
        }
        setIsActionIncomingModalOpen(!isActionIncomingModalOpen);
    };
    const toggleStoppedActionModal = (offer: OfferData, index: number) => {
        const stoppedItem = stoppedItemRefs.current[index];
        if (stoppedItem) {
            const {right, bottom} = stoppedItem.getBoundingClientRect();
            setActionActiveModalPosition({
                x: right,
                y: bottom,
            });
            setActiveOffer(offer);
        }
        setIsActionStoppedModalOpen(!isActionStoppedModalOpen);
    };
    const toggleEndedActionModal = (offer: OfferData, index: number) => {
        const endedItem = endedItemRefs.current[index];
        if (endedItem) {
            const {right, bottom} = endedItem.getBoundingClientRect();
            setActionActiveModalPosition({
                x: right,
                y: bottom,
            });
            setActiveOffer(offer);
        }
        setIsActionEndedModalOpen(!isActionEndedModalOpen);
    };


     const toggleDetailsModal = () => {
        // setIsDetailsModalOpen(!isDetailsModalOpen);
        // setIsActionModalOpen(false);
    };
     const toggleExtendModal = ()=>{
        setExtendModalIsOpen(!extendModalIsOpen);
        setIsActionActiveModalOpen(false);
        setIsActionIncomingModalOpen(false);
        setIsActionEndedModalOpen(false);
        setIsActionStoppedModalOpen(false);
     }

     const toggleEndModal = ()=>{
        setEndModalIsOpen(!endModalIsOpen);
        setIsActionActiveModalOpen(false);
        setIsActionIncomingModalOpen(false);
        setIsActionEndedModalOpen(false);
        setIsActionStoppedModalOpen(false);
     }

     const togglePayModal = ()=>{
        setPayOfferModalIsOpen(!payOfferModalIsOpen);
        setIsActionActiveModalOpen(false);
        setIsActionIncomingModalOpen(false);
        setIsActionEndedModalOpen(false);
        setIsActionStoppedModalOpen(false);
     }

     const toggleEditModal = ()=>{
        setEditModalIsOpen(!editModalIsOpen);
        setIsActionActiveModalOpen(false);
        setIsActionIncomingModalOpen(false);
        setIsActionEndedModalOpen(false);
        setIsActionStoppedModalOpen(false);
     }

     const toggleDuplicateModal = ()=>{
        setDuplicateModalIsOpen(!duplicateModalIsOpen);
        setIsActionActiveModalOpen(false);
        setIsActionIncomingModalOpen(false);
        setIsActionEndedModalOpen(false);
        setIsActionStoppedModalOpen(false);
     }

    let activeActions = [
        {
            title: 'Edytuj',
            onClick: toggleEditModal,
            className: ''
        },
        {
            title: 'Przedłuż',
            onClick: toggleExtendModal,
            className: ''
        },
        {
            title: 'Duplikuj',
            onClick: toggleDuplicateModal,
            className: ''
        },
        {
            title: 'Zakończ',
            onClick: toggleEndModal,
            className: 'red'
        },
    ];

    let stoppedActions = [
        {
            title: 'Edytuj',
            onClick: toggleEditModal,
            className: ''
        },
        {
            title: 'Przedłuż',
            onClick: toggleExtendModal,
            className: ''
        },
        {
            title: 'Duplikuj',
            onClick: toggleDuplicateModal,
            className: ''
        },
        {
            title: 'Opłać promocję',
            onClick: togglePayModal,
            className: ''
        },
        {
            title: 'Zakończ',
            onClick: toggleEndModal,
            className: 'red'
        },
    ];

    let endedActions = [
        {
            title: 'Duplikuj',
            onClick: toggleDuplicateModal,
            className: ''
        },
    ];

    let incomingActions = [
        {
            title: 'Edytuj',
            onClick: toggleEditModal,
            className: ''
        },
        {
            title: 'Duplikuj',
            onClick: toggleDuplicateModal,
            className: ''
        },
        {
            title: 'Zakończ',
            onClick: toggleEndModal,
            className: 'red'
        },
    ];



    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={()=>closeOfferManagerModal}
            overlayClassName="modal-wrapper"
            className="manager-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title">Zarządzaj promocjami</h5>
                        <div className="tags-wrapper">
                            <div
                                className={`tag ${activeTag === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTag('all')}
                            >Wszystkie
                            </div>
                            {!isEmpty(active) &&
                                <div
                                    className={`tag ${activeTag === 'active' ? 'active' : ''}`}
                                    onClick={() => setActiveTag('active')}
                                >Aktywne
                                </div>
                            }
                            {!isEmpty(incoming) &&
                                <div
                                    className={`tag ${activeTag === 'incoming' ? 'active' : ''}`}
                                    onClick={() => setActiveTag('incoming')}
                                >Nadchodzące
                                </div>
                            }
                            {!isEmpty(stopped) &&
                                <div
                                    className={`tag ${activeTag === 'stopped' ? 'active' : ''}`}
                                    onClick={() => setActiveTag('stopped')}
                                >Wstrzymane
                                </div>
                            }
                            {!isEmpty(ended) &&
                                <div
                                    className={`tag ${activeTag === 'ended' ? 'active' : ''}`}
                                    onClick={() => setActiveTag('ended')}
                                >Zakończone
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal-close" onClick={closeOfferManagerModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    {(activeTag === 'all' || activeTag === 'stopped') && !isEmpty(stopped) &&
                        <div className="section">
                            <p className="label red">Wstrzymane</p>
                            <div className="list-wrapper">
                                {stopped.map((offer, index) => (
                                    <div
                                        className="offer red"
                                         key={`Stopped-Offer${index}`}
                                        ref={(ref) => (stoppedItemRefs.current[index] = ref)}
                                    >
                                        <div className="title">
                                            <p>{offer.name}</p>
                                        </div>
                                        <div className="info">
                                            <p>Pozostało:&nbsp;
                                                <span
                                                    className="blue">{(offer.end_date && getHowDaysLeft(offer.end_date))}</span>
                                            </p>
                                        </div>
                                        <div onClick={()=>toggleStoppedActionModal(offer, index)}>
                                            <img className="action-dots" src={icons.actionsButtonIcon}
                                                 alt='action button icon'/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <ActionModal isOpen={isActionStoppedModalOpen}
                                 onClose={() => setIsActionStoppedModalOpen(false)}
                                 actions={stoppedActions}
                         x={actionActiveModalPosition.x} y={actionActiveModalPosition.y}/>
                    {(activeTag === 'all' || activeTag === 'active') && !isEmpty(active)  &&
                        <div className="section">
                            <p className="label ">Aktywne</p>
                            <div className="list-wrapper">
                                {active.map((offer, index) => (
                                    <div
                                        className="offer"
                                        key={`Active-Offer${index}`}
                                        ref={(ref) => (activeItemRefs.current[index] = ref)}
                                    >
                                        <div className="title">
                                            <p>{offer.name}</p>
                                        </div>
                                        <div className="info">
                                            <p>Pozostało:&nbsp;
                                                <span
                                                    className="blue">{(offer.end_date && getHowDaysLeft(offer.end_date))}</span>
                                            </p>
                                        </div>
                                        <div onClick={()=>toggleActiveActionModal(offer, index)}>
                                            <img className="action-dots" src={icons.actionsButtonIcon}
                                                 alt='action button icon'/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <ActionModal isOpen={isActionActiveModalOpen}
                                 onClose={() => setIsActionActiveModalOpen(false)}
                                 actions={activeActions}
                         x={actionActiveModalPosition.x} y={actionActiveModalPosition.y}/>
                    {(activeTag === 'all' || activeTag === 'incoming') && !isEmpty(incoming) &&
                        <div className="section">
                        <p className="label ">Nadchodzące</p>
                            <div className="list-wrapper">
                                {incoming.map((offer, index) => (
                                    <div
                                        className="offer"
                                         key={`Incoming-Offer${index}`}
                                        ref={(ref) => (incomingItemRefs.current[index] = ref)}
                                    >
                                        <div className="title">
                                            <p>{offer.name}</p>
                                        </div>
                                        <div className="info">
                                            <p>Początek promocji:&nbsp;
                                                <span
                                                    className="blue">{dayjs(offer.start_date).format('DD.MM.YYYY')}</span>
                                            </p>
                                        </div>
                                        <div onClick={()=>toggleIncomingActionModal(offer, index)}>
                                            <img className="action-dots" src={icons.actionsButtonIcon}
                                                 alt='action button icon'/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <ActionModal isOpen={isActionIncomingModalOpen}
                                 onClose={() => setIsActionIncomingModalOpen(false)}
                                 actions={incomingActions}
                         x={actionActiveModalPosition.x} y={actionActiveModalPosition.y}/>
                    {(activeTag === 'all' || activeTag === 'ended') && !isEmpty(ended) &&
                        <div className="section">
                        <p className="label ">Zakończone</p>
                            <div className="list-wrapper">
                                {ended.map((offer, index) => (
                                    <div
                                        className="offer"
                                         key={`Ended-Offer${index}`}
                                        ref={(ref) => (endedItemRefs.current[index] = ref)}
                                    >
                                        <div className="title">
                                            <p className="gray">{offer.name}</p>
                                        </div>
                                        <div className="info">
                                            <p>Zakończone dnia:&nbsp;
                                                <span
                                                    className="gray">{dayjs(offer.start_date).format('DD.MM.YYYY')}</span>
                                            </p>
                                        </div>
                                        <div onClick={()=>toggleEndedActionModal(offer, index)}>
                                            <img className="action-dots" src={icons.actionsButtonIcon}
                                                 alt='action button icon'/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <ActionModal isOpen={isActionEndedModalOpen}
                                 onClose={() => setIsActionEndedModalOpen(false)}
                                 actions={endedActions}
                                 x={actionActiveModalPosition.x}
                                 y={actionActiveModalPosition.y}
                    />
                    <EndOfferModal
                        isOpen={endModalIsOpen}
                        onClose={()=>setEndModalIsOpen(false)}
                        offer={activeOffer}
                    />
                    <ExtendOfferModal
                        isOpen={extendModalIsOpen}
                        onClose={()=>setExtendModalIsOpen(false)}
                        offer={activeOffer}
                    />
                    <PayOfferModal
                         isOpen={payOfferModalIsOpen}
                        onClose={() => setPayOfferModalIsOpen(false)}
                        offer={activeOffer}
                    />
                    <EditOfferModal
                        isOpen={editModalIsOpen}
                        onClose={()=>setEditModalIsOpen(false)}
                        offer={activeOffer}
                    />
                    <DuplicateOfferModal
                        isOpen={duplicateModalIsOpen}
                        onClose={()=>setDuplicateModalIsOpen(false)}
                        offer={activeOffer}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default OfferManagerModal;

