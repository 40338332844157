import {AdminSidebarItemName, ServiceTypeName, SidebarItemName} from "./interfaces";

export const cloudFrontUrl = 'https://d38l8mdnw1ohzc.cloudfront.net/';
const cloudFrontIconsUrl = `${cloudFrontUrl}icons/`;
const cloudFrontBankIconsUrl = `${cloudFrontUrl}bank_icons/`;
const cloudLogoCategoryIconsUrl = `${cloudFrontUrl}logo-category-icons/`;

export const images = {
    bottomBlackDecoration: `${cloudFrontUrl}dark-bottom-bar.svg`,
    bottomBlueDecoration: `${cloudFrontUrl}blue-bottom-bar.svg`,
    offerImagePlaceHolder: `${cloudFrontUrl}promo-photo-placeholder.png`,
    dashboardOfferImagePlaceHolder: `${cloudFrontUrl}image-dashboard-placeholder.png`,
    dashboardOfferVideoPlaceHolder: `${cloudFrontUrl}video-dashboard-placeholder.png`,
    onboardingBusiness: `${cloudFrontUrl}onboarding-business.svg`,
    onboardingTelephone: `${cloudFrontUrl}onboarding-telephone.svg`,
    onboardingTerminal: `${cloudFrontUrl}onboarding-terminal.svg`,
    payProfitAdminLogoBlack: `${cloudFrontUrl}logo-pp-admin-panel.png`,
    payProfitAdminLogoWhite: `${cloudFrontUrl}logo-pp-admin-panel-white.png`,
    payProfitCRMLogoBlack: `${cloudFrontUrl}pp-crm-logo-black.png`,
    payProfitCRMLogoWhite: `${cloudFrontUrl}pp-crm-logo-white.png`,
    payProfitTransparent: `${cloudFrontUrl}payprofit-icon-transparent.png`,
    topBlackDecoration: `${cloudFrontUrl}top_black.svg`,
    topBlueDecoration: `${cloudFrontUrl}top_blue.svg`,
};


export const icons = {
    actionsButtonIcon: `${cloudFrontIconsUrl}actions-button-icon.svg`,
    addBackgroundImageIcon: `${cloudFrontIconsUrl}add_background_image_icon.svg`,
    addBlueWhiteIcon: `${cloudFrontIconsUrl}add-blue-white-icon.svg`,
    addFundsIcon: `${cloudFrontIconsUrl}add-funds-icon.svg`,
    addIcon: `${cloudFrontIconsUrl}add-icon.svg`,
    addWhiteIcon: `${cloudFrontIconsUrl}add-white-icon.svg`,
    arrowRightIcon: `${cloudFrontIconsUrl}arrow-right-icon.svg`,
    backIcon: `${cloudFrontIconsUrl}back-icon.svg`,
    barChartActiveIcon: `${cloudFrontIconsUrl}bar-chart-active-icon.svg`,
    barChartIcon: `${cloudFrontIconsUrl}bar-chart-icon.svg`,
    blackArrowUpIcon: `${cloudFrontIconsUrl}black-arrow-up-icon.svg`,
    blockFundsIcon: `${cloudFrontIconsUrl}block-funds-icon.svg`,
    blueCalendarIcon: `${cloudFrontIconsUrl}blue-calendar-crm-icon.svg`,
    blueLineIcon: `${cloudFrontIconsUrl}blue_line.svg`,
    brandIcon: `${cloudFrontIconsUrl}pp-brand-icon.svg`,
    calendarIcon: `${cloudFrontIconsUrl}calendar.svg`,
    cashbackCoinIcon: `${cloudFrontIconsUrl}cashback-icon.svg`,
    cashbackPaidIcon: `${cloudFrontIconsUrl}cashback-paid-icon.svg`,
    checkIcon: `${cloudFrontIconsUrl}check.svg`,
    closeIcon: `${cloudFrontIconsUrl}close-icon.svg`,
    cogIcon: `${cloudFrontIconsUrl}cog-crm-icon.svg`,
    cogWhiteIcon: `${cloudFrontIconsUrl}cog-white-icon.svg`,
    coinBlueLowIcon: `${cloudFrontIconsUrl}coin-blue-low-icon.svg`,
    coinBluePrimaryIcon: `${cloudFrontIconsUrl}coin-blue-primary-icon.svg`,
    coinGrayIcon: `${cloudFrontIconsUrl}coin-gray-icon.svg`,
    coinGreenIcon: `${cloudFrontIconsUrl}coin-green-icon.svg`,
    coinRedIcon: `${cloudFrontIconsUrl}coin-red-icon.svg`,
    coinYellowIcon: `${cloudFrontIconsUrl}coin-yellow-icon.svg`,
    colorPickerIcon: `${cloudFrontIconsUrl}color_picker_icon.svg`,
    cupCollectedActionIcon: `${cloudFrontIconsUrl}cup-collected-action-icon.svg`,
    cupCollectedIcon: `${cloudFrontIconsUrl}cup-collected-crm-icon.svg`,
    cupExchangedActionIcon: `${cloudFrontIconsUrl}cup-exchanged-action-icon.svg`,
    cupExchangedIcon: `${cloudFrontIconsUrl}cup-exchanged-crm-icon.svg`,
    cupReturnedActionIcon: `${cloudFrontIconsUrl}cup-returned-action-icon.svg`,
    cupReturnedIcon: `${cloudFrontIconsUrl}cup-returned-crm-icon.svg`,
    cupUsedActionIcon: `${cloudFrontIconsUrl}cup-used-action-icon.svg`,
    cupUsedIcon: `${cloudFrontIconsUrl}small-cup-icon.svg`,
    discountIcon: `${cloudFrontIconsUrl}discount-icon.svg`,
    download: `${cloudFrontIconsUrl}download-icon.svg`,
    emailIcon: `${cloudFrontIconsUrl}email-crm-icon.svg`,
    errorIcon: `${cloudFrontIconsUrl}error-icon.svg`,
    filterGrey: `${cloudFrontIconsUrl}filter-icon.svg`,
    fullNameIcon: `${cloudFrontIconsUrl}fullname-crm-icon.svg`,
    greyCalendar: `${cloudFrontIconsUrl}grey-calendar-icon.svg`,
    greyCalendar2: `${cloudFrontIconsUrl}grey-calendar-icon2.svg`,
    greyLineIcon: `${cloudFrontIconsUrl}grey_line.svg`,
    greyPinIcon: `${cloudFrontIconsUrl}grey_pin.svg`,
    infoGrayIcon: `${cloudFrontIconsUrl}info-icon-2.svg`,
    infoIcon: `${cloudFrontIconsUrl}info-icon-1.svg`,
    lineChartActiveIcon: `${cloudFrontIconsUrl}line-chart-active-icon.svg`,
    lineChartIcon: `${cloudFrontIconsUrl}line-chart-icon.svg`,
    locationPinIcon: `${cloudFrontIconsUrl}location-pin-icon.svg`,
    lockActive: `${cloudFrontIconsUrl}lock-active-icon.svg`,
    logoutActive: `${cloudFrontIconsUrl}logout-active-icon.svg`,
    megaphoneIcon: `${cloudFrontIconsUrl}megaphone-icon.svg`,
    messagesIcon: `${cloudFrontIconsUrl}messages-icon.svg`,
    navigateToMeIcon: `${cloudFrontIconsUrl}navigate-to-me-icon.svg`,
    newCalendarIcon: `${cloudFrontIconsUrl}calendar-icon.svg`,
    noBookingsIcon: `${cloudFrontIconsUrl}no-bookings-icon.svg`,
    noDataGraphIcon: `${cloudFrontIconsUrl}no-data-graph-icon.svg`,
    offerCalendarIcon: `${cloudFrontIconsUrl}blue-calendar-icon.svg`,
    passwordIcon: `${cloudFrontIconsUrl}password-crm-icon.svg`,
    penActiveIcon: `${cloudFrontIconsUrl}pen-crm-active-icon.svg`,
    penIcon: `${cloudFrontIconsUrl}pen-crm-icon.svg`,
    pencilIcon: `${cloudFrontIconsUrl}pencil-icon.svg`,
    plusIcon: `${cloudFrontIconsUrl}plus.svg`,
    plusWhiteIcon: `${cloudFrontIconsUrl}plus-white-icon.svg`,
    prevStepIcon: `${cloudFrontIconsUrl}prev_step.svg`,
    previewIcon: `${cloudFrontIconsUrl}preview.svg`,
    promotionPaidIcon: `${cloudFrontIconsUrl}promotion-paid-icon.svg`,
    questionMarkIcon: `${cloudFrontIconsUrl}question-mark-icon.svg`,
    redExitIcon: `${cloudFrontIconsUrl}red-exit-icon.svg`,
    redWarningIcon: `${cloudFrontIconsUrl}red-warning-icon.svg`,
    removeActive: `${cloudFrontIconsUrl}remove-active-icon.svg`,
    removeWhiteIcon: `${cloudFrontIconsUrl}remove-white-icon.svg`,
    resetFilters: `${cloudFrontIconsUrl}reset-filters.svg`,
    returnsBigIcon: `${cloudFrontIconsUrl}returns-big-icon.svg`,
    returnsSmallIcon: `${cloudFrontIconsUrl}returns-small-icon.svg`,
    returnsSmallWhiteIcon: `${cloudFrontIconsUrl}returns-small-white-icon.svg`,
    rightArrowBlueIcon: `${cloudFrontIconsUrl}chevron-right-blue.svg`,
    rightArrowGrayIcon: `${cloudFrontIconsUrl}chevron-right-gray.svg`,
    rightArrowIcon: `${cloudFrontIconsUrl}chevron-right.svg`,
    search: `${cloudFrontIconsUrl}search-icon.svg`,
    selectTargetGroup: `${cloudFrontIconsUrl}select-target-group.svg`,
    sendIcon: `${cloudFrontIconsUrl}send-icon.svg`,
    step1GreyIcon: `${cloudFrontIconsUrl}step_1_grey.svg`,
    step1Icon: `${cloudFrontIconsUrl}step_1.svg`,
    step2GreyIcon: `${cloudFrontIconsUrl}step_2_grey.svg`,
    step2Icon: `${cloudFrontIconsUrl}step_2.svg`,
    step3GreyIcon: `${cloudFrontIconsUrl}step_3_grey.svg`,
    step3Icon: `${cloudFrontIconsUrl}step_3.svg`,
    step4GreyIcon: `${cloudFrontIconsUrl}step_4_grey.svg`,
    step4Icon: `${cloudFrontIconsUrl}step_4.svg`,
    successIcon: `${cloudFrontIconsUrl}success-icon.svg`,
    telephoneIcon: `${cloudFrontIconsUrl}telephone-crm-icon.svg`,
    textAlignLeftIcon: `${cloudFrontIconsUrl}text_align_left_icon.svg`,
    textAlignLeftIconInactive: `${cloudFrontIconsUrl}text_align_left_icon_inactive.svg`,
    textCenteredIcon: `${cloudFrontIconsUrl}text_centered_icon.svg`,
    textCenteredIconInactive: `${cloudFrontIconsUrl}text_centered_icon_inactive.svg`,
    textSizeIcon: `${cloudFrontIconsUrl}text_size_icon.svg`,
    textSizeIconInactive: `${cloudFrontIconsUrl}text_size_icon_inactive.svg`,
    transparentCoinIcon: `${cloudFrontIconsUrl}transparent-coin-icon.svg`,
    unblockFundsIcon: `${cloudFrontIconsUrl}unblock-funds-icon.svg`,
    userActive: `${cloudFrontIconsUrl}user-active-icon.svg`,
    userProfileIcon: `${cloudFrontIconsUrl}menu-profile-icon.svg`,
    verificationTerminalIllustration: `${cloudFrontIconsUrl}verification-terminal-illustration.svg`,
    waycupIcon: `${cloudFrontIconsUrl}waycup-icon.svg`,
    whiteCheckIcon: `${cloudFrontIconsUrl}white-check-icon.svg`,
    whiteCoinIcon: `${cloudFrontIconsUrl}white-coin-icon.svg`,
    whitePinIcon: `${cloudFrontIconsUrl}white-pin-icon.svg`,
};

export const sidebarIcons = {
    accounting: `${cloudFrontIconsUrl}statistics-sidebar-icon.svg`,
    accountingActive: `${cloudFrontIconsUrl}statistics-sidebar-active-icon.svg`,
    contact: `${cloudFrontIconsUrl}contact-sidebar-icon.svg`,
    contactActive: `${cloudFrontIconsUrl}contact-sidebar-active-icon.svg`,
    cups: `${cloudFrontIconsUrl}cups-sidebar-icon.svg`,
    cupsActive: `${cloudFrontIconsUrl}cups-sidebar-active-icon.svg`,
    forest: `${cloudFrontIconsUrl}forest-sidebar-icon.svg`,
    forestActive: `${cloudFrontIconsUrl}forest-sidebar-active-icon.svg`,
    history: `${cloudFrontIconsUrl}history-sidebar-icon.svg`,
    historyActive: `${cloudFrontIconsUrl}history-sidebar-active-icon.svg`,
    home: `${cloudFrontIconsUrl}home-sidebar-icon.svg`,
    homeActive: `${cloudFrontIconsUrl}home-sidebar-active-icon.svg`,
    merchants: `${cloudFrontIconsUrl}history-sidebar-icon.svg`,
    merchantsActive: `${cloudFrontIconsUrl}history-sidebar-active-icon.svg`,
    offers: `${cloudFrontIconsUrl}offers-sidebar-icon.svg`,
    offersActive: `${cloudFrontIconsUrl}offers-sidebar-active-icon.svg`,
    payouts: `${cloudFrontIconsUrl}payouts-sidebar-icon.svg`,
    payoutsActive: `${cloudFrontIconsUrl}payouts-sidebar-icon-active.svg`,
    settings: `${cloudFrontIconsUrl}settings-sidebar-icon.svg`,
    settingsActive: `${cloudFrontIconsUrl}settings-sidebar-active-icon.svg`,
    statistics: `${cloudFrontIconsUrl}statistics-sidebar-icon.svg`,
    statisticsActive: `${cloudFrontIconsUrl}statistics-sidebar-active-icon.svg`,
    users: `${cloudFrontIconsUrl}history-sidebar-icon.svg`,
    usersActive: `${cloudFrontIconsUrl}history-sidebar-active-icon.svg`,
    booking: `${cloudFrontIconsUrl}booking-sidebar-icon.svg`,
    bookingActive: `${cloudFrontIconsUrl}booking-sidebar-active-icon.svg`,
};


export const serviceTypeIcons: { [key: string]: string } = {
    animal: `${cloudFrontIconsUrl}gray-animal-icon.png`,
    animalActive: `${cloudFrontIconsUrl}animal-icon.png`,
    beauty: `${cloudFrontIconsUrl}gray-beauty-icon.png`,
    beautyActive: `${cloudFrontIconsUrl}beauty-icon.png`,
    car: `${cloudFrontIconsUrl}gray-car-icon.png`,
    carActive: `${cloudFrontIconsUrl}car-icon.png`,
    child: `${cloudFrontIconsUrl}gray-child-icon.png`,
    childActive: `${cloudFrontIconsUrl}child-icon.png`,
    culture: `${cloudFrontIconsUrl}gray-theater-icon.png`,
    cultureActive: `${cloudFrontIconsUrl}theater-icon.png`,
    education: `${cloudFrontIconsUrl}gray-education-icon.png`,
    educationActive: `${cloudFrontIconsUrl}education-icon.png`,
    electronic: `${cloudFrontIconsUrl}gray-games-icon.png`,
    electronicActive: `${cloudFrontIconsUrl}games-icon.png`,
    fashion: `${cloudFrontIconsUrl}gray-fashion-icon.png`,
    fashionActive: `${cloudFrontIconsUrl}fashion-icon.png`,
    gastronomy: `${cloudFrontIconsUrl}gray-gastronomy-icon.png`,
    gastronomyActive: `${cloudFrontIconsUrl}gastronomy-icon.png`,
    health: `${cloudFrontIconsUrl}gray-heart-icon.png`,
    healthActive: `${cloudFrontIconsUrl}heart-icon.png`,
    home: `${cloudFrontIconsUrl}gray-home-icon.png`,
    homeActive: `${cloudFrontIconsUrl}home-icon.png`,
    property: `${cloudFrontIconsUrl}gray-property-icon.png`,
    propertyActive: `${cloudFrontIconsUrl}property-icon.png`,
    shop: `${cloudFrontIconsUrl}gray-shop-icon.png`,
    shopActive: `${cloudFrontIconsUrl}shop-icon.png`,
    sport: `${cloudFrontIconsUrl}gray-sports-icon.png`,
    sportActive: `${cloudFrontIconsUrl}sports-icon.png`,
    tourism: `${cloudFrontIconsUrl}gray-tourism-icon.png`,
    tourismActive: `${cloudFrontIconsUrl}tourism-icon.png`,
};

export const logoCategoryIcons: { [key: string]: string } = {
    ANIMAL: `${cloudLogoCategoryIconsUrl}animal.png`,
    BEAUTY: `${cloudLogoCategoryIconsUrl}beauty.png`,
    CAR: `${cloudLogoCategoryIconsUrl}car.png`,
    CHILD: `${cloudLogoCategoryIconsUrl}child.png`,
    CULTURE: `${cloudLogoCategoryIconsUrl}culture.png`,
    EDUCATION: `${cloudLogoCategoryIconsUrl}education.png`,
    ELECTRONIC: `${cloudLogoCategoryIconsUrl}electronic.png`,
    FASHION: `${cloudLogoCategoryIconsUrl}fashion.png`,
    GASTRONOMY: `${cloudLogoCategoryIconsUrl}gastronomy.png`,
    HEALTH: `${cloudLogoCategoryIconsUrl}health.png`,
    HOME: `${cloudLogoCategoryIconsUrl}home.png`,
    PROPERTY: `${cloudLogoCategoryIconsUrl}property.png`,
    SHOP: `${cloudLogoCategoryIconsUrl}shop.png`,
    SPORT: `${cloudLogoCategoryIconsUrl}sport.png`,
    TOURISM: `${cloudLogoCategoryIconsUrl}tourism.png`,
};

export const getSidebarIcon = (key: SidebarItemName, isActive: boolean) => sidebarIcons[`${key}${isActive ? "Active" : ''}`];
export const getAdminSidebarIcon = (key: AdminSidebarItemName, isActive: boolean) => sidebarIcons[`${key}${isActive ? "Active" : ''}`];
export const getServiceTypeIcon = (key: ServiceTypeName, isActive: boolean) => serviceTypeIcons[`${key.toLowerCase()}${isActive ? "Active" : ''}`];
export const getBankIcon = (path: string) => `${cloudFrontBankIconsUrl}${path}`;
