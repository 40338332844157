import {
    ChartInterval,
    DateRange,
    OfferDashboardData,
    OfferData,
    OfferSendData,
    PreviousPeriod,
    RangeBase,
    RangeBaseCreateData,
    TargetGroup,
    TargetGroupCreateData,
    UpdateOfferData
} from "../services/interfaces";
import {store} from "../redux/store";
import {
    setCurrentOfferAction, setOfferDashboardAction,
    setOffersAction,
} from "../redux/merchant";
import {
    createOffer,
    getMerchantOffers, getMerchantPointOffers,
    getMerchantOfferDashboard,
    updateOffer, getMerchantPointOfferDashboard
} from "../http/offers";
import {getCurrentMerchantPoint, getCurrentOffer, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {isEmpty, isEqual} from "lodash";
import {setScreenLoadingAction} from "../redux/navigation";


export const getOffersHandler = (): Promise<OfferData[]> => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const offerPromise = !isMerchantSelected && merchantPoint && merchantPoint.id
        ? getMerchantPointOffers(merchantPoint.id)
        : getMerchantOffers();

    return offerPromise
        .then((data: OfferData[]) => {
            store.dispatch(setOffersAction(data));
            !isEmpty(data) && store.dispatch(setCurrentOfferAction(data[0]));
            return data;
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

export const createOfferHandler = (formData: FormData) => {
    createOffer(formData)
        .then((data: OfferData) => {
            getOffersHandler().then(() => {
                store.dispatch(setCurrentOfferAction(data));
            })
        }).catch(error => {
        console.error(error);
    })
}

export const updateOfferHandler = (originalOffer: OfferData, editedOffer: OfferData) => {

    const addDataFromBasicFields = (updateData: UpdateOfferData) => {
        const basicFields: (keyof OfferData)[] = ["clients", 'name', 'target_group', 'offer_localizations', 'estimated_cost', 'number_of_customers', 'extra_customers', 'offer_user_changes',
            "type", "extra_cashback", "offer_merchant_points", "start_date", "end_date", "offer_days", "usage", "description", "direct_addressing", "direct_addressing_style", "send_push_notification",
            "is_over", "empty_deposit", "use_deposits", "image_path", "film_path", "film_title", "film_layout", "film_file"];
        basicFields.forEach(field => {
            if (editedOffer[field] !== originalOffer[field]) {
                updateData[field] = editedOffer[field] as any;
            }
        });
    };

    if (editedOffer.id) {
        const updateData: UpdateOfferData = {};
        addDataFromBasicFields(updateData);
        const sendData = new FormData();
        const offerData: UpdateOfferData = {
            ...updateData,
        }
        sendData.append('data', JSON.stringify(offerData));
        if (updateData.film_file) {
            sendData.append('film_file', updateData.film_file);
        }
        updateOffer(editedOffer.id, sendData).then((updatedOffer: OfferData) => {
            getOffersHandler().then(() => {
                store.dispatch(setCurrentOfferAction(updatedOffer));
            })
        }).catch(error => {
            console.error(error);
        })
    }
};

export const updateSendOfferHandler = (originalOffer: OfferSendData,
                                       editedOffer: OfferSendData) => {

    if (!editedOffer.id) editedOffer.id = originalOffer.id;

    const prepareCreateData = (targetGroup: TargetGroup): TargetGroupCreateData => {
        const {id, ...target_group} = targetGroup;
        const removeIdFromRange = (range: RangeBase): RangeBaseCreateData => {
            const {id, ...rest} = range;
            return rest;
        };
        return {
            ...target_group,
            age_ranges: targetGroup.age_ranges.map(removeIdFromRange),
            transactions_ranges: targetGroup.transactions_ranges.map(removeIdFromRange),
            amount_ranges: targetGroup.amount_ranges.map(removeIdFromRange),
            amount_sum_ranges: targetGroup.amount_sum_ranges.map(removeIdFromRange),
        };
    };

    const addDataFromFields = (updateData: UpdateOfferData, withoutTG = false) => {

        const basicFields: (keyof OfferSendData)[] = ["clients", 'name', 'estimated_cost', 'number_of_customers', 'extra_customers',
            "type", "extra_cashback", "start_date", "end_date", "usage", "description", "direct_addressing", "direct_addressing_style", "send_push_notification",
            "is_over", "empty_deposit", "use_deposits", "image_path", "film_path", "film_title", "film_layout", "film_file"];
        const arrayFields: (keyof OfferSendData)[] = ['target_group', 'offer_localizations', 'offer_user_changes',
            "merchant_point_ids", "offer_days"];
        const arrayFieldsWithoutTG: (keyof OfferSendData)[] = ['offer_localizations', 'offer_user_changes',
            "merchant_point_ids", "offer_days"];
        basicFields.forEach(field => {
            if (editedOffer[field] !== originalOffer[field]) {
                updateData[field] = editedOffer[field] as any;
            }
        });
        if (withoutTG) {
            arrayFieldsWithoutTG.forEach(arr => {
                if (!isEqual(editedOffer[arr], originalOffer[arr])) {
                    updateData[arr] = editedOffer[arr] as any;
                }
            })
        } else {
            arrayFields.forEach(arr => {
                if (!isEqual(editedOffer[arr], originalOffer[arr])) {
                    updateData[arr] = editedOffer[arr] as any;
                }
            })
        }
    };
    if (editedOffer.id) {
        const updateData: UpdateOfferData = {};
        const sendData = new FormData();

        if ("id" in editedOffer.target_group && "id" in originalOffer.target_group && editedOffer.target_group.id !== originalOffer.target_group.id) {
            const createTargetGroup = prepareCreateData(editedOffer.target_group);
            addDataFromFields(updateData, true);

            const offerData: UpdateOfferData = {
                ...updateData,
                target_group: createTargetGroup,
                new_target_group: true,
            }

            sendData.append('data', JSON.stringify(offerData));
            if (updateData.film_file) {
                sendData.append('film_file', updateData.film_file);
            }
            updateOffer(editedOffer.id, sendData).then((updatedOffer: OfferData) => {
                getOffersHandler().then(() => {
                    store.dispatch(setCurrentOfferAction(updatedOffer));
                })
            }).catch(error => {
                console.error(error);
            })
        } else {
            addDataFromFields(updateData);
            const offerData: UpdateOfferData = {
                ...updateData,
            }
            sendData.append('data', JSON.stringify(offerData));
            if (updateData.film_file) {
                sendData.append('film_file', updateData.film_file);
            }
            updateOffer(editedOffer.id, sendData).then((updatedOffer: OfferData) => {
                getOffersHandler().then(() => {
                    store.dispatch(setCurrentOfferAction(updatedOffer));
                })
            }).catch(error => {
                console.error(error);
            })
        }
    }
};

export const getOfferDashboardDataHandler = (
    dateRange: DateRange,
    previousPeriod: PreviousPeriod,
    chartInterval: ChartInterval,
    withLoading?: boolean,
    offer_id?: number,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const currentOffer = getCurrentOffer(store.getState())
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const currentOfferID = currentOffer.id ? currentOffer.id : 0;
    const offerID = offer_id ? offer_id : currentOfferID;
    if (!offerID) return;
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    if (withLoading) store.dispatch(setScreenLoadingAction(true));
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointOfferDashboard(offerID, merchantPoint.id, urlParam.toString()).then((dashboardData: OfferDashboardData) => {
            store.dispatch(setOfferDashboardAction(dashboardData));
            if (withLoading) store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            if (withLoading) store.dispatch(setScreenLoadingAction(false));
        })
    } else {
        getMerchantOfferDashboard(offerID, urlParam.toString()).then((dashboardData: OfferDashboardData) => {
            store.dispatch(setOfferDashboardAction(dashboardData));
            if (withLoading) store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            if (withLoading) store.dispatch(setScreenLoadingAction(false));
        })
    }
}