import Modal from "react-modal";
import {icons} from "../../services/images";
import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {getCurrentOffer} from "../../redux/selectors/merchantSelector";
import Button from "../common/Button";
import {OfferData} from "../../services/interfaces";
import {updateOfferHandler} from "../../handlers/offerHandlers";
import FiltersDatePicker from "./filters/FiltersDatePicker";


interface ExtendOfferModalProps {
    isOpen: boolean,
    onClose: () => void;
    offer?: OfferData;
}

const ExtendOfferModal = ({
                              isOpen,
                              onClose,
                              offer,
                          }: ExtendOfferModalProps) => {

    const currentOffer = useSelector(getCurrentOffer);
    const [startDate, setStartDate] = useState<Date | null>(currentOffer.start_date);
    const [endDate, setEndDate] = useState<Date | null>(currentOffer.end_date);

    useEffect(() => {
        if (offer) {
            setStartDate(offer.start_date);
            setEndDate(offer.end_date);
        } else {
            setStartDate(currentOffer.start_date);
            setEndDate(currentOffer.end_date);
        }
    }, [currentOffer, offer]);

    const updateOfferEndDate = (offer: OfferData) => {
        if (endDate) {
            endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
        }
        const newOffer = {...offer, is_over: false, end_date: endDate}
        updateOfferHandler(offer, newOffer);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="extend-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title">Przedłuż promocję</h5>
                        <div className="icon-wrapper">
                            <Tooltip id="extend offer" className="tooltip" noArrow={true}>
                                <p className="tooltip-body">Tekst tooltip dotyczy przedłużenia promocji.</p>
                            </Tooltip>
                            <img src={icons.infoIcon} className="info-icon" alt="info" data-tooltip-id="extend offer"
                                 data-tooltip-place="top"/>
                        </div>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="section">
                        <div className="select-wrapper">
                            <p className="label label-margin">Czas trwania</p>
                            <FiltersDatePicker
                                minTodayDate={true}
                                maxTodayDate={false}
                                title={null}
                                withIcon
                                disabledStart
                                fromDate={startDate}
                                setFromDate={(date) => {
                                    setStartDate(date);
                                }}
                                toDate={endDate}
                                setToDate={(date) => {
                                    setEndDate(date);
                                }
                                }
                            />
                        </div>
                    </div>
                    <Button label="Zapisz i zastosuj"
                            fullWidth
                            onClick={() => {
                                if (offer) {
                                    updateOfferEndDate(offer)
                                } else {
                                    updateOfferEndDate(currentOffer)
                                }
                                onClose()
                            }}
                            type="Primary"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ExtendOfferModal;