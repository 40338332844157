import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseNotification} from "../services/interfaces";
import getNotificationMessage from "../services/dictionaries/notification";


interface NavigationState {
    shouldBlock: boolean;
    responseNotification: ResponseNotification | null;
    screenLoading: boolean
    modals: {
        deposit: boolean;
        returns: boolean;
        offers: boolean;
        contact: boolean;
        settings: boolean;
    }
}

const DEFAULT_STATE: NavigationState = {
    shouldBlock: false,
    responseNotification: null,
    screenLoading: false,
    modals: {
        deposit: false,
        returns: false,
        offers: false,
        contact: false,
        settings: false,
    }
};

export type NavigationModal = 'deposit' | 'returns' | 'offers' | 'contact' | 'settings';


const navigationSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'navigation',
    reducers: {
        setShouldBlock: (state, action: PayloadAction<boolean>) => {
            return {...state, shouldBlock: action.payload}
        },
        setResponseNotification: (state, action: PayloadAction<ResponseNotification | null>) => {
            return {...state, responseNotification: action.payload ? {status: action.payload.status, message: getNotificationMessage(action.payload.message)} : null}
        },
        setScreenLoading: (state, action: PayloadAction<boolean>) => {
            return {...state, screenLoading: action.payload}
        },
        setResponseNotificationSaved: (state) => {
            return {...state, responseNotification: {status: "success", message: getNotificationMessage("saved")}}
        },
        setResponseNotificationError: (state) => {
            return {...state, responseNotification: {status: "error", message: getNotificationMessage("error")}}
        },
        modalOpen: (state, action: PayloadAction<NavigationModal>) => {
            return {...state, modals: {...state.modals, [action.payload]: true}}
        },
        modalClose: (state, action: PayloadAction<NavigationModal>) => {
            return {...state, modals: {...state.modals, [action.payload]: false}}
        }
    }
});

export default navigationSlice.reducer;

export const setShouldBlockNavigation = navigationSlice.actions.setShouldBlock;
export const setResponseNotificationAction = navigationSlice.actions.setResponseNotification;
export const setResponseNotificationSavedAction = navigationSlice.actions.setResponseNotificationSaved;
export const setResponseNotificationErrorAction = navigationSlice.actions.setResponseNotificationError;
export const setScreenLoadingAction = navigationSlice.actions.setScreenLoading;
export const modalOpenAction = navigationSlice.actions.modalOpen;
export const modalCloseAction = navigationSlice.actions.modalClose;
