import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import NavigationSidebar from "../components/navigation/NavigationSidebar";
import {getUserDetailsHandler} from "../handlers/authHandlers";
import {getUserDetails} from "../redux/selectors/authSelectors";
import {useDispatch, useSelector} from "react-redux";
import {Merchant, MerchantUserDetails} from "../services/interfaces";
import {CONFIGURE_MERCHANT_PAGE, CUPS_PAGE, VERIFY_PHONE_PAGE} from "../services/dictionaries/paths";
import {getMerchantHandler} from "../handlers/merchantHandler";
import LoadingScreen from "../components/common/LoadingScreen";
import {getMerchant, getOffers} from "../redux/selectors/merchantSelector";
import {getScreenLoading} from "../redux/selectors/navigationSelectors";
import {setScreenLoadingAction} from "../redux/navigation";
import {getMerchantReturnsHandler} from "../handlers/transactionHandlers";
import WarningBar from "../components/common/WarningBar";
import {getChatsHandler} from "../handlers/chatHandlers";
import {getOffersHandler} from "../handlers/offerHandlers";


const NavigationContainer = () => {
    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);
    const merchant: Merchant | null = useSelector(getMerchant);
    const offers = useSelector(getOffers);
    const navigate = useNavigate();
    const screenLoading = useSelector(getScreenLoading);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setScreenLoadingAction(false))
    }, [location]);

    useEffect(() => {
        !userDetails && getUserDetailsHandler();
        userDetails && !userDetails.is_superuser  && navigate(CUPS_PAGE);
    }, [userDetails, navigate]);

    useEffect(() => {
        if (userDetails) {
            if (!userDetails.is_verified) {
                navigate(VERIFY_PHONE_PAGE);
            }
        }
    }, [userDetails, navigate, merchant]);

    useEffect(() => {
        if (merchant && !merchant?.nip) {
            navigate(CONFIGURE_MERCHANT_PAGE);
        }
    }, [navigate, merchant]);

    useEffect(() => {
        userDetails && userDetails.merchant && !merchant && getMerchantHandler(true, true);
        getOffersHandler();
    }, [userDetails, merchant]);

    useEffect(() => {
        if (userDetails && userDetails.is_superuser) {
            getMerchantReturnsHandler();
            getChatsHandler();
        }
    }, [userDetails]);

    if (!userDetails || !merchant)
        return <LoadingScreen/>;

    return (
        <div className="main-wrapper">
            <WarningBar merchant={merchant} offers={offers}/>
            <NavigationSidebar/>
            {screenLoading && <div className="loading-wrapper">
                <LoadingScreen/>
            </div>}

            <div className="site-content">
                <Outlet/>
            </div>
        </div>
    )
};

export default NavigationContainer;